import { ActiviteRecensement } from "./../models/activite.model";
import {
  Activite,
  ActiviteDTO,
  ElementKey,
  ActiviteEquipeConstition,
} from "@/models";
import { toast } from "@/store";
import { TYPE } from "@/store/modules/toast.module";
import { Http } from "@/utils/http";

export default class ActiviteService {
  public static async activite(id: string): Promise<Activite> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activite&id=${id}`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async create(
    activite: ActiviteDTO,
    optionPreSaisieVehicule = false,
    optionPreSaisieCompetences = false
  ): Promise<ElementKey> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteCreate`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        activite,
        optionPreSaisieVehicule,
        optionPreSaisieCompetences,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "Activité créé",
        "",
        true,
        TYPE.SUCCESS
      );
    }
    return response.data?.id;
  }

  public static async liaisonMateriel(
    activite: string,
    materiel: ElementKey
  ): Promise<ElementKey> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteMateriel`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        activite,
        materiel,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
    }
    return response.data?.id;
  }

  public static async liaisonVehicule(
    activite: string,
    vehicule: ElementKey
  ): Promise<ElementKey> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteVehicule`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        activite,
        vehicule,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
    }
    return response.data?.id;
  }

  public static async liaisonCompetence(
    activite: string,
    competence: ElementKey,
    number: number
  ): Promise<ElementKey> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteBesoin`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        competence: {
          activite: activite,
          id: competence.id,
          nombre: number,
        },
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
    }
    return response.data?.id;
  }

  public static async update(activite: Activite): Promise<ElementKey> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteUpdate`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        activite,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
    }
    return response.data?.id;
  }

  public static async delete(id: string, notify: boolean): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteDelete`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        id,
        notify,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async annulerActivite(id: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteAnnuler`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        id,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async bloquerRecensement(id: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteRecensementBloquer`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        id,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async ouvrir(id: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteRecensementOuvrir`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        id,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async fermerEtEquipe(
    id: string,
    equipe: ActiviteRecensement[]
  ): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteFermer`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        id,
        equipe,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async relance(id: string, message: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activiteRelance`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        id,
        message,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async messageAttente(id: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=messageSrEngage&activite_id=${id}`;
    const { data: response } = await Http.instance.get(targetUrl);
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }
}
