






































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";

@Component({
  components: {
    Sidebar,
    Cta,
    Tag,
  },
})
export default class TableauDeBord extends Vue {}
