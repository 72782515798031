













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Tabs extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: true }) choices!: string;

  private select(key: number | string) {
    this.$emit("input", key);
  }
}
