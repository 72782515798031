























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import Datepicker from "@/components/forms/datepicker.vue";
import Modal from "@/components/modal/modal.vue";
import Card from "@/components/card/card.vue";
import Tabs from "@/components/tabs/Tab.vue";
import Radio from "@/components/radio/radio.vue";
import Checkbox from "@/components/checkbox/checkbox.vue";
import Alert, { TypeAlert } from "@/components/alert/alert.vue";
import SwitchValue from "@/components/switch/switch.vue";
import { activites, login, data, toast, benevoles } from "@/store";
import {
  Activite,
  ActiviteBesoin,
  ActiviteElementKey,
  ActiviteEquipeConstition,
  ActiviteRecensement,
  ActiviteRecensementState,
  Benevole,
  ElementKey,
  User,
  VehiculeMinimized,
} from "@/models";
import { found, isSameDay, select, selectBesoin } from "@/helpers";
import dayjs from "dayjs";
import ActiviteService from "@/services/activite.service";
import TimelineService from "@/services/timeline.service";

@Component({
  methods: {
    isSameDay,
    found,
  },
  components: {
    Sidebar,
    Cta,
    Tag,
    Datepicker,
    Modal,
    Card,
    SwitchValue,
    Tabs,
    Alert,
    Radio,
    Checkbox,
  },
})
export default class ActiviteDetail extends Vue {
  private data: Activite | null = null;
  private hasUpdated = false;
  private modalAddCompetence = false;
  private competenceAdd: ActiviteElementKey | null = null;
  private competenceAddNumber = 1;
  private fermetureModal = false;
  private constituerEquipeModal = false;
  private fermetureConfirmModal = false;
  private typeFermeture = "";
  private deleteModal = false;
  private duplicateModal = false;
  private relanceModal = false;
  private messageRelance = "";
  private engagerModal = false;
  private searchSecouriste = "";
  private selectedSecouriste: Benevole | ActiviteRecensement | null = null;
  private closeWithContitution = true;
  private equipeConstitution: ActiviteRecensement[] = [];

  private tabSelected = "fiche";
  private tabs = [
    { key: "fiche", label: "Fiche" },
    { key: "recensement", label: "Recensement" },
    { key: "equipe", label: "Equipe" },
    { key: "slack", label: "Slack" },
  ];

  async mounted(): Promise<void> {
    await this.getDatas();
    this.hasUpdated = false;
  }

  private async getDatas(): Promise<void> {
    await activites.activite(this.$route.params.id);
    await data.typesActivites();
    await data.typesDO();
    await data.vehicules();
    await data.materiels();
    await data.competences();
    await benevoles.benevoles();
    this.data = ({ ...activites.getActivite } as Activite) ?? null;
    this.setDefaultMessageRelance();
    this.equipeConstitution =
      this.data?.recensement
        ?.filter((r) => r.etat.id === "ENGAGE")
        .map((r) => {
          return { ...r, competence: null, selected: false };
        }) ?? [];
  }

  get activiteDetail(): Activite | null {
    return activites.getActivite;
  }

  get types(): ActiviteElementKey[] | null {
    return data.getTypesActivite;
  }

  get typesDo(): ElementKey[] | null {
    return data.getTypesDO;
  }

  get vehicules(): VehiculeMinimized[] | null {
    return data.getVehicules;
  }

  get materiels(): ElementKey[] | null {
    return data.getMateriels;
  }

  get allCompetences(): ElementKey[] | null {
    return data.getCompetences;
  }

  private competencesSecouriste(sr: ActiviteRecensement): ElementKey[] | null {
    if (!this.allCompetences) {
      return null;
    }
    const competencesAvailable: ElementKey[] = [];
    this.allCompetences.forEach((c) => {
      if (sr.competences?.includes(c.id)) {
        competencesAvailable.push(c);
      }
    });
    return competencesAvailable;
  }

  private competencesSecouristeString(sr: ActiviteRecensement): string | null {
    return (
      this.competencesSecouriste(sr)
        ?.map((c) => {
          return c.libelle;
        })
        ?.join(", ") ?? null
    );
  }

  get competences(): ElementKey[] | null {
    if (this.data && this.data.besoin && data.getCompetences) {
      return data.getCompetences.filter((el) => {
        if (this.data && this.data.besoin) {
          return !found(this.data.besoin, el);
        }
      });
    } else {
      return null;
    }
  }

  get ActiviteRecensementState(): typeof ActiviteRecensementState {
    return ActiviteRecensementState;
  }

  get TypeAlert(): typeof TypeAlert {
    return TypeAlert;
  }

  get context(): User | null {
    return login.getContext;
  }

  get today(): string {
    return dayjs().format("YYYY-MM-DD HH:mm:ss");
  }

  get personnesEngagesList(): ActiviteRecensement[] {
    return this.data?.recensement?.filter((r) => r.etat.id === "ENGAGE") ?? [];
  }

  get personnesNonDispoList(): ActiviteRecensement[] {
    return this.data?.recensement?.filter((r) => r.etat.id === "NDISP") ?? [];
  }

  get personnesEngages(): string {
    if (this.data && this.data.recensement) {
      return this.personnesEngagesList
        .map((r) => {
          return r.nom_usage;
        })
        .join(", ");
    } else {
      return "Indisponible";
    }
  }

  get personnesNonDisponible(): string {
    if (this.data && this.data.recensement) {
      return this.data.recensement
        ?.filter((r) => r.etat.id === "NDISP")
        .map((r) => {
          return r.nom_usage;
        })
        .join(", ");
    } else {
      return "Indisponible";
    }
  }

  get vehiculeList(): string {
    if (this.data && this.data.vehicule) {
      if (this.data.vehicule.length) {
        return this.data.vehicule
          .map((v) => {
            return v.libelle;
          })
          .join(",");
      } else {
        return "Aucun véhicule engagé";
      }
    } else {
      return "Données indisponible";
    }
  }

  get materielList(): string {
    if (this.data && this.data.materiel) {
      if (this.data.materiel.length) {
        return this.data.materiel
          .map((m) => {
            return m.libelle;
          })
          .join(",");
      } else {
        return "Aucun matériel lié";
      }
    } else {
      return "Données indisponible";
    }
  }

  private benevoles(search: string): Benevole[] | null {
    if (benevoles.getBenevoles && search) {
      return benevoles.getBenevoles
        .filter((b) => b.nom_usage.toLowerCase().includes(search.toLowerCase()))
        .slice(0, 3);
    } else {
      return null;
    }
  }

  private setDefaultMessageRelance(): void {
    this.messageRelance = `Bonjour, Tu n'as pas indiqué ta disponibilité pour l'activité ${
      this.activiteDetail?.type?.libelle
    } ${this.activiteDetail?.libelle} du ${dayjs(
      this.activiteDetail?.debut
    ).format("DD/MM/YYYY")} à ${dayjs(this.activiteDetail?.debut).format(
      "HH:mm"
    )} au ${dayjs(this.activiteDetail?.fin).format("DD/MM/YYYY")} à ${dayjs(
      this.activiteDetail?.fin
    ).format("HH:mm")}.`;
    if (
      this.activiteDetail &&
      this.activiteDetail?.type &&
      (this.activiteDetail.type.id === "1" ||
        this.activiteDetail.type.id === "2")
    ) {
      this.messageRelance += ` Les OPÉ ont besoin de cette information. Peux-tu l'indiquer sur Timeline ? Merci.`;
    }
    if (
      this.activiteDetail &&
      this.activiteDetail?.type &&
      this.activiteDetail.type.id === "3"
    ) {
      this.messageRelance += ` L'ACSSO a besoin de cette information. Peux-tu l'indiquer sur Timeline ? Merci.`;
    }
    if (
      this.activiteDetail &&
      this.activiteDetail?.type &&
      (this.activiteDetail.type.id === "4" ||
        this.activiteDetail.type.id === "5" ||
        this.activiteDetail.type.id === "6")
    ) {
      this.messageRelance += ` L'équipe FOR a besoin de cette information. Peux-tu l'indiquer sur Timeline ? Merci.`;
    }
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private selectTypeDO(selected: ActiviteElementKey): void {
    if (this.data) {
      this.data.do = selected;
    }
  }

  private selectType(selected: ActiviteElementKey): void {
    if (this.data) {
      this.data.type = selected;
    }
  }

  private async switchMateriel(selected: ActiviteElementKey): Promise<void> {
    if (this.data && this.data.id && this.data.materiel) {
      this.data.materiel = select(this.data.materiel, selected);
    }
  }

  private async switchVehicule(selected: ActiviteElementKey): Promise<void> {
    if (this.data && this.data.id && this.data.vehicule) {
      this.data.vehicule = select(this.data.vehicule, selected);
    }
  }

  private switchCompetenceAdd(selected: ActiviteElementKey): void {
    this.competenceAdd = selected;
  }

  private switchCompetenceModal(state: boolean): void {
    this.modalAddCompetence = state;
    this.competenceAdd = null;
    this.competenceAddNumber = 1;
  }

  private updateCompetence(besoin: ActiviteBesoin): void {
    this.switchCompetenceModal(true);
    this.switchCompetenceAdd(besoin);
    this.competenceAddNumber = besoin.nombre_requis;
  }

  private async removeCompetence(besoin: ActiviteBesoin): Promise<void> {
    this.competenceAdd = besoin;
    await this.addComptence();
  }

  private async addComptence(): Promise<void> {
    if (this.data && this.data.id && this.data.besoin && this.competenceAdd) {
      const selected: ActiviteBesoin = {
        id: this.competenceAdd.id,
        libelle: this.competenceAdd.libelle,
        nombre_requis: this.competenceAddNumber,
      };
      this.data.besoin = selectBesoin(this.data.besoin, selected);
      this.competenceAdd = null;
      this.competenceAddNumber = 0;
      this.switchCompetenceModal(false);
    }
  }

  private async cancelUpdate(): Promise<void> {
    await this.getDatas();
    this.hasUpdated = false;
  }

  private async update(): Promise<void> {
    if (this.data) {
      await ActiviteService.update(this.data);
      await this.getDatas();
      this.hasUpdated = false;
    }
  }

  // ENGAGER
  private engagerSecouriste(): void {
    this.engagerModal = true;
  }
  private cancelEngager(): void {
    this.engagerModal = false;
    this.searchSecouriste = "";
    this.selectedSecouriste = null;
  }
  private selectSecouristeEngager(selected: Benevole): void {
    if (this.data) {
      this.searchSecouriste = selected.nom_usage;
      this.selectedSecouriste = selected;
    }
  }
  private async confirmEngager(
    secouriste = this.selectedSecouriste,
    state = ActiviteRecensementState.ENGAGE,
    partie?: string
  ): Promise<void> {
    if (this.data && this.data.id && secouriste) {
      if (
        await TimelineService.recensement(
          partie ?? this.data.id,
          state,
          secouriste.id ?? secouriste.user_id,
          true
        )
      ) {
        this.cancelEngager();
        await this.getDatas();
        this.hasUpdated = false;
        this.tabSelected = "recensement";
      }
    } else {
      toast.pushError("Le secouriste à engager n'est pas sélectionné");
    }
  }

  // SUPPRESSION
  private deleteActivite(): void {
    this.deleteModal = true;
  }
  private cancelDelete(): void {
    this.deleteModal = false;
  }
  private async confirmDelete(notify: boolean): Promise<void> {
    if (await ActiviteService.delete(this.$route.params.id, notify)) {
      this.deleteModal = false;
      this.$router.push("/activites/gestions");
    }
  }

  // FERMETURE RECENSEMENT
  private async fermerRecensement(): Promise<void> {
    if (this.typeFermeture === "equipe") {
      this.fermetureModal = false;
      this.constituerEquipeModal = true;
    }
    if (this.typeFermeture === "annuler") {
      if (await ActiviteService.annulerActivite(this.$route.params.id)) {
        await this.getDatas();
        this.hasUpdated = false;
        this.fermetureModal = false;
        this.typeFermeture = "";
      }
    }
    if (this.typeFermeture === "temporaire") {
      if (await ActiviteService.bloquerRecensement(this.$route.params.id)) {
        await this.getDatas();
        this.hasUpdated = false;
        this.fermetureModal = false;
        this.typeFermeture = "";
      }
    }
  }
  private async confirmConstitutionEquipe(): Promise<void> {
    if (this.data && this.data.id) {
      const equipe = this.equipeConstitution.filter((e) => e.selected);
      if (await ActiviteService.fermerEtEquipe(this.data.id, equipe)) {
        await this.getDatas();
        this.hasUpdated = false;
        this.constituerEquipeModal = false;
      }
    }
  }

  // RELANCE
  private async confirmRelance(): Promise<void> {
    if (
      await ActiviteService.relance(this.$route.params.id, this.messageRelance)
    ) {
      this.relanceModal = false;
    }
  }

  // MESSAGE D'ATTENTE
  private async messageAttente(): Promise<void> {
    await ActiviteService.messageAttente(this.$route.params.id);
  }

  private duplicateActivite(): void {
    this.duplicateModal = true;
  }

  private cancelDuplicate(): void {
    this.duplicateModal = false;
  }

  private relanceActivite(): void {
    this.relanceModal = true;
  }

  private cancelRelance(): void {
    this.relanceModal = false;
    this.setDefaultMessageRelance();
  }

  private fermerActivite(): void {
    this.fermetureModal = true;
  }

  private async ouvrirActivite(): Promise<void> {
    if (await ActiviteService.ouvrir(this.$route.params.id)) {
      await this.getDatas();
      this.hasUpdated = false;
    }
  }

  private cancelFermeture(): void {
    this.fermetureModal = false;
    this.typeFermeture = "";
  }

  private cancelConstitutionEquipe(): void {
    this.constituerEquipeModal = false;
    this.fermetureModal = true;
  }

  private closeFermetureConfirmModal(): void {
    this.fermetureConfirmModal = false;
  }

  @Watch("data.type")
  @Watch("data.libelle")
  @Watch("data.debut")
  @Watch("data.fin")
  @Watch("data.lieu")
  @Watch("data.description")
  @Watch("data.reversion")
  @Watch("data.do.id")
  @Watch("data.materiel")
  @Watch("data.vehicule")
  @Watch("data.besoin")
  private setActiteHasUpdated(): void {
    this.hasUpdated = true;
  }
}
