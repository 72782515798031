




































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import CardProchaineActivite from "@/components/card/cardProchaineActivite.vue";
import CardCalendrier from "@/components/card/cardCalendrier.vue";
import CardActivite from "@/components/card/cardActivite.vue";
import Alert from "@/components/alert/alert.vue";
import panelActivite from "@/components/panel/panelActivite.vue";
import Modal from "@/components/modal/modal.vue";
import Cta from "@/components/buttons/Button.vue";
import { login, toast } from "@/store";
import { found, select } from "@/helpers";
import { User, OnboardingSteps } from "@/models";
import LoginService from "@/services/login.service";

@Component({
  methods: {
    found,
    select,
  },
  components: {
    Sidebar,
    CardProchaineActivite,
    Cta,
    CardCalendrier,
    CardActivite,
    Alert,
    panelActivite,
    Modal,
  },
})
export default class Onboarding extends Vue {
  private dataReady = false;

  async mounted(): Promise<void> {
    await login.onboarding();
  }

  private async validOnboarding() {
    if (await LoginService.onboardingValidation()) {
      await login.context();
      this.$router.replace("/timeline");
    } else {
      toast.pushError(
        "Ton onboarding n'a pas pu être cloturé. Pas de panique, réessayes ou bien contacte Jordan sur Slack."
      );
    }
  }

  private goFC() {
    this.$router.push({ name: "Recyclage" });
  }

  get context(): User | null {
    return login.getContext;
  }
  get onboarding(): OnboardingSteps | null {
    return login.getOnboarding;
  }

  get recyclageDemande(): boolean {
    return this.context?.roles.includes("ACCES_FC") ?? false;
  }
}
