




























































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import tag from "@/components/tags/Tag.vue";
import PanelOption from "@/components/option/option.vue";
import cta from "@/components/buttons/Button.vue";
import { login, verifAssist } from "@/store";
import { found, select, isSameDay } from "@/helpers";
import { LotMesVerifications, User } from "@/models";
import dayjs from "dayjs";

@Component({
  methods: {
    found,
    select,
    isSameDay,
  },
  components: {
    Sidebar,
    tag,
    PanelOption,
    cta,
  },
})
export default class VerificationAssistee extends Vue {
  private dataReady = false;

  private optionVerif = false;
  private verifSelected: LotMesVerifications | null = null;

  async mounted(): Promise<void> {
    await verifAssist.mesVerifications();
    this.dataReady = true;
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private openVerification(verif: LotMesVerifications): void {
    this.verifSelected = verif;
    this.verifSelected.verification.sort((a, b) =>
      a.statut > b.statut ? 1 : -1
    );
    this.optionVerif = true;
  }

  private closeVerification(): void {
    this.optionVerif = false;
  }

  get context(): User | null {
    return login.getContext;
  }

  get mesVerifs(): LotMesVerifications[] | null {
    return verifAssist.getMesVerifications;
  }
}
