















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PanelOption extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ default: false }) forceBottomSheet?: boolean;
  @Prop({ default: false }) extend?: boolean;

  private closeOption(): void {
    this.$emit("close");
  }
}
