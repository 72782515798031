












import { Component, Prop, Vue } from "vue-property-decorator";

interface TypeTag {
  id: string;
  libelle: string;
}

@Component({
  components: {},
})
export default class Tag extends Vue {
  @Prop({ required: true }) type!: TypeTag | string | null;
  @Prop({ required: false, default: false }) small?: boolean;
}
