





























































import { Component, Vue } from "vue-property-decorator";
import ButtonAction from "@/components/buttons/Button.vue";
import { toast } from "@/store";
import { TYPE } from "@/store/modules/toast.module";
import LoginService from "@/services/login.service";
import { LocalStorageApp } from "@/utils/localstorage";

@Component({
  components: { ButtonAction },
})
export default class Login extends Vue {
  private username = "";
  private userpasswd = "";

  private backgroundImage = {
    backgroundImage: `url(${require("@/assets/images/photos/jop-lco.jpg")})`,
  };

  async mounted(): Promise<void> {
    const token: string = LocalStorageApp.getItem("pcp.token") ?? "";
    if (token && !this.$route.query.change_compte) {
      this.$router.push("/deverrouiller");
    }
  }

  private async login() {
    if (this.username && this.userpasswd) {
      if (await LoginService.login(this.username, this.userpasswd)) {
        this.$router.replace("/");
      } else {
        this.userpasswd = "";
      }
    } else {
      toast.createAndPushToast(
        "Veuillez saisir un nom d'utilisateur et un mot de passe",
        "",
        true,
        TYPE.INFO
      );
    }
  }
}
