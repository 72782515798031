












































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import SwitchToggle from "@/components/switch/switch.vue";
import PanelOption from "@/components/option/option.vue";
import { User, UserCalendrier } from "@/models";
import { login, toast, profile } from "@/store";
import { TYPE } from "@/store/modules/toast.module";

@Component({
  components: {
    Sidebar,
    Cta,
    SwitchToggle,
    PanelOption
  },
})
export default class MonCalendrier extends Vue {
  private linkICS: string | null = null;

  private optionCalendrier = false;

  private param_retenu = false;
  private param_prov = false;
  private param_busy = false;

  mounted() {
    this.setLinkICS();
    this.getSettings();
  }

  private copy() {
    if (this.linkICS) {
      this.$copyText(this.linkICS).then(
        function () {
          toast.createAndPushToast(
            "Copié !",
            "Le lien de votre calendrier a bien été copié",
            true,
            TYPE.DEFAULT
          );
        },
        function (e) {
          toast.pushError("Le lien n'a pas pu être copié");
          console.log(e);
        }
      );
    }
  }

  private openOption(): void {
    this.optionCalendrier = true;
  }

  private closeOption(): void {
    this.optionCalendrier = false;
  }

  private openWiki(): void {
    window.open("http://wiki.protectioncivile-paris8.org/index.php?title=Mon_calendrier_-_Timeline", "_blank");
  }

  @Watch("context")
  private setLinkICS(): void {
    this.linkICS = `https://timeline.protectioncivile-paris8.org/api/ics/?key=${this.context?.api_key}`;
  }

  @Watch("context")
  private getSettings(): void {
    if (this.context) {
      this.param_retenu = this.context.preferences.calendrier.retenu;
      this.param_prov = this.context.preferences.calendrier.provisoire;
      this.param_busy = this.context.preferences.calendrier.busy;
    }
  }

  private setSettings(): void {
    if (this.context) {
      profile.updateParamCalendrier({retenu: this.param_retenu, provisoire: this.param_prov, busy: this.param_busy} as UserCalendrier);
    }
  }

  get context(): User | null {
    return login.getContext;
  }
}
