























































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Datepicker from "@/components/forms/datepicker.vue";
import { Activite, ElementKey } from "@/models";
import { data } from "@/store";

@Component({
  components: {
    Sidebar,
    Cta,
    Datepicker,
  },
})
export default class BenevoleCreate extends Vue {
  private dataReady = false;

  async mounted(): Promise<void> {
    await data.roles();
    await data.competences();
    this.dataReady = true;
  }

  get roles(): ElementKey[] | null {
    return data.getRoles;
  }

  get competences(): ElementKey[] | null {
    return data.getCompetences;
  }

  private async createActivite(activite: Activite): Promise<void> {
    // if (
    //   activite.libelle &&
    //   activite.debut &&
    //   activite.fin &&
    //   activite.do?.id !== "0" &&
    //   activite.type?.id !== "0"
    // ) {
    //   const idCreate = await ActiviteService.create(mapOutActivite(activite));
    //   if (idCreate) {
    //     this.$router.push(`/activite/${idCreate}`);
    //   }
    // } else {
    //   toast.pushError("Veuillez remplir l'ensemble des champs obligatoire");
    // }
  }
}
