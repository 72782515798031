






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Button extends Vue {
  @Prop({ default: "primary" }) variant!: string;
  @Prop({ default: false }) block!: boolean;
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: false }) large!: boolean;
  @Prop({ default: false }) round!: boolean;
  @Prop({ default: false }) selectable?: boolean;
  @Prop({ default: false }) checkable?: boolean;
  @Prop({ default: false }) active?: boolean;
  @Prop({ default: false }) disabled?: boolean;
}
