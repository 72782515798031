



































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";

@Component({
  components: {
    DatePicker,
  },
})
export default class Datepicker extends Vue {
  private datetimeValue: any | null = null;

  @Prop() value!: any;

  mounted(): void {
    if (this.value) {
      this.datetimeValue = this.value;
    }
  }

  @Watch("datetimeValue")
  updateDatetimeValue(): void {
    this.$emit("update:datetime", this.datetimeValue);
    this.$emit("input", this.datetimeValue);
  }

  @Watch("value")
  updateValue(): void {
    this.datetimeValue = this.value;
  }
}
