import { UserCalendrier } from "@/models";
import ProfileService from "@/services/profile.service";
import { Module, VuexModule, Action } from "vuex-class-modules";

@Module
export class Profile extends VuexModule {

  @Action
  async updateParamCalendrier(settings: UserCalendrier): Promise<boolean> {
    try {
      await ProfileService.updateParamCalendrier(settings);
      return true;
    } catch {
      return false;
    }
  }
}
