import { ElementKey } from ".";

export enum ActiviteRecensementState {
  "ENGAGE" = "ENGAGE",
  "NDISP" = "NDISP",
}

export interface MonSuiviActivite {
  historique: ProchaineActivite[];
  score: number;
}

export interface ProchaineActivite {
  id: string;
  libelle: string;
  debut: string;
  fin: string;
  type: ActiviteElementKey;
  competence: ActiviteElementKey;
}

export interface ActivitePartie {
  id: string;
  libelle: string;
  debut: string;
  fin: string;
  recensement: ActiviteRecensement[];
  disponibilite?: ActiviteDisponibilite | null;
}

export interface Activite {
  id: string | null;
  libelle: string | null;
  debut: string | null;
  fin: string | null;
  type: ActiviteElementKey | null;
  do: ActiviteElementKey | null;
  etat: ActiviteElementKey | null;
  description?: string | null;
  lieu?: string | null;
  reversion?: string | null;
  disponibilite?: ActiviteDisponibilite | null;
  info_secouriste?: ActiviteInfoSecourite | null;
  besoin?: ActiviteBesoin[] | null;
  equipe?: ActiviteEquipe[] | null;
  recensement?: ActiviteRecensement[] | null;
  materiel?: ActiviteElementKey[] | null;
  vehicule?: ActiviteElementKey[] | null;
  parties: ActivitePartie[] | null;
  slack_id?: string | null;
  slack_name?: string | null;
}

export interface ActiviteElementKey {
  id: string;
  libelle: string;
}

export interface ActiviteDisponibilite {
  engage: number;
  non_disponible: number;
  total_recence: number;
}

export interface ActiviteRecensement {
  id?: string;
  user_id: string;
  nom_usage: string;
  etat: ActiviteElementKey;
  competences?: string[];
  selected?: boolean;
  competence?: ActiviteElementKey | null;
}

export interface ActiviteEquipe {
  user_id: string;
  nom_usage: string;
  competence: ActiviteElementKey;
}

export interface ActiviteInfoSecourite {
  statut: string;
  competence?: ActiviteElementKey;
}

export interface ActiviteBesoin extends ActiviteElementKey {
  nombre_requis: number;
}
export interface ActiviteEquipeConstition extends ActiviteElementKey {
  user: ActiviteRecensement;
  competence: ActiviteElementKey;
}

export interface ActiviteDTO {
  libelle: string;
  debut: string;
  fin: string;
  type_id: string;
  do_etat: string;
  etat_id: string;
  lieu: string;
  reversion: string;
}

export interface payloadTimeline {
  types: ElementKey[];
  list: string;
}

export interface JopContext {
  saisieCommencee: boolean;
  modificationPossible: boolean;
  secteur: ActiviteElementKey;
  secteurs: ActiviteElementKey[];
  acceptionNuit: boolean;

  // JOP
  calendar: boolean[];
}
