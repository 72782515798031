var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"www www--simple"},[_c('sidebar'),_c('h1',{staticClass:"m-0 mb-24"},[_vm._v("Statistiques d'activités")]),(_vm.Statistiques && _vm.Statistiques.progression)?_c('div',{staticClass:"mb-32"},[_vm._m(0),_c('div',{staticClass:"card card--shadow bg-white"},[_c('div',{staticClass:"p-16"},[_c('div',{staticClass:"graph-activite graph-activite--large"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"datas"},_vm._l((_vm.Statistiques.stats),function(graph,index){return _c('div',{key:index,staticClass:"data"},[_c('div',{staticClass:"progress my-24"},[_c('div',{staticClass:"bg-secondary",style:({
                      height: (((graph.nbActivites / _vm.Statistiques.progression.max) *
                        100) + "%"),
                    })})]),_c('div',{staticClass:"legend"},[_vm._v(" "+_vm._s(graph.nbActivites)+" ")])])}),0),_c('small',[_vm._v("Evolution du nombre d'activités par mois")])])])])])]):_vm._e(),(_vm.Statistiques && _vm.Statistiques.stats)?_c('div',{staticClass:"container container--gap-3"},[_c('h3',{staticClass:"m-0"},[_vm._v("Détails des 12 derniers mois")]),_vm._l((_vm.Statistiques.stats),function(periode,index){return _c('div',{key:index,staticClass:"card card--shadow bg-white"},[_c('div',{staticClass:"flex flex-mobile flex__justify-between flex__align-center p-16"},[_c('h3',{staticClass:"m-0"},[_vm._v(_vm._s(periode.libelle))]),_c('div',{staticClass:"flex flex-mobile"},[_c('p',{staticClass:"m-0 text-neutral--300 mr-12"},[_c('span',{staticClass:"text-neutral--400"},[_vm._v(_vm._s(periode.nbSecouriste))]),_vm._v(" secouristes ")]),_c('p',{staticClass:"m-0 text-neutral--300"},[_c('span',{staticClass:"text-neutral--400"},[_vm._v(_vm._s(periode.nbActivites))]),_vm._v(" activités ")])])]),(periode.nbActivites)?_c('div',[_c('div',{staticClass:"p-16"},[_c('div',{staticClass:"graph-activite graph-activite--stats"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"datas"},_vm._l((periode.results),function(activite,index){return _c('div',{key:index,staticClass:"data"},[_c('div',{staticClass:"progress my-24"},[(activite && activite.activites)?_c('div',{class:("bg-activite--" + (activite.code)),style:({
                        height: ((activite.activites.pourcentage) + "%"),
                      })}):_vm._e()]),(activite && activite.activites)?_c('div',{staticClass:"legend"},[_vm._v(" "+_vm._s(activite.activites.nombre)+" ")]):_vm._e()])}),0),_c('small',[_vm._v("Nombre d'activités")])]),_c('div',{staticClass:"col pt-12"},[_c('div',{staticClass:"datas"},_vm._l((periode.results),function(activite,index){return _c('div',{key:index,staticClass:"data"},[_c('div',{staticClass:"progress my-24"},[(activite.activites)?_c('div',{class:("bg-activite--" + (activite.code) + "-light"),style:({
                        height: ((activite.activites.pourcentage) + "%"),
                      })},[(activite.reponse)?_c('div',{class:("bg-activite--" + (activite.code)),style:({
                          height: ((activite.reponse.pourcentage) + "%"),
                        })}):_vm._e()]):_vm._e()]),(activite.reponse)?_c('div',{staticClass:"legend"},[_vm._v(" "+_vm._s(activite.reponse ? activite.reponse.pourcentage : 0)+"% ")]):_vm._e()])}),0),_c('small',[_vm._v("Taux de réponse")])]),_c('div',{staticClass:"col pt-12"},[_c('div',{staticClass:"datas"},_vm._l((periode.results),function(activite,index){return _c('div',{key:index,staticClass:"data"},[_c('div',{staticClass:"progress my-24"},[(activite.activites)?_c('div',{class:("bg-activite--" + (activite.code) + "-light"),style:({
                        height: ((activite.activites.pourcentage) + "%"),
                      })},[(activite.disponible)?_c('div',{class:("bg-activite--" + (activite.code)),style:({
                          height: ((activite.disponible.pourcentage) + "%"),
                        })}):_vm._e()]):_vm._e()]),(activite.disponible)?_c('div',{staticClass:"legend"},[_vm._v(" "+_vm._s(activite.disponible.nombre ? activite.disponible.pourcentage : 0)+"% ")]):_vm._e()])}),0),_c('small',[_vm._v("Taux de disponibilité")])])])]),_c('hr'),_vm._m(1,true),_vm._l((periode.results),function(results,index){return _c('div',{key:index},[_c('hr'),_c('div',{staticClass:"flex flex-mobile flex__justify-between flex__align-center px-16 py-12"},[_c('div',[_c('p',{staticClass:"m-0 text-neutral--300"},[_vm._v(_vm._s(results.libelle))])]),_c('div',{staticClass:"flex flex-mobile flex__align-center"},[(results.activites)?_c('div',{staticClass:"mr-32"},[_c('small',{staticClass:"m-0 text-right text-neutral--300"},[_vm._v(" Activités ")]),_c('p',{staticClass:"m-0 text-right font-16 bold"},[_vm._v(" "+_vm._s(results.activites.nombre)+" ")])]):_vm._e(),(results.reponse)?_c('div',{staticClass:"mr-32"},[_c('small',{staticClass:"m-0 text-right text-neutral--300"},[_vm._v(" Réponses ")]),_c('p',{staticClass:"m-0 text-right font-16 bold"},[_vm._v(" "+_vm._s(results.reponse.pourcentage)+" % ")])]):_vm._e(),(results.reponse && results.disponible)?_c('div',[_c('small',{staticClass:"m-0 text-right text-neutral--300"},[_vm._v(" Disponibilités ")]),_c('p',{staticClass:"m-0 text-right font-16 bold"},[_vm._v(" "+_vm._s(results.disponible.pourcentage)+" % ")])]):_vm._e(),(!results.reponse || !results.disponible)?_c('div',[_vm._m(2,true)]):_vm._e()])])])})],2):_vm._e()])})],2):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-12"},[_c('h3',{staticClass:"m-0 mb-4"},[_vm._v("Progression du nombre d'activité par catégorie")]),_c('p',{staticClass:"m-0 small"},[_vm._v("Du plus récent au plus ancien")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"element-list flex__justify-center p-16"},[_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{staticClass:"graph-legend bg-activite--dps mr-4"}),_c('p',{staticClass:"font-14 bold m-0"},[_vm._v("DPS")])]),_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{staticClass:"graph-legend bg-activite--garde mr-4"}),_c('p',{staticClass:"font-14 bold m-0"},[_vm._v("Garde")])]),_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{staticClass:"graph-legend bg-activite--maraude mr-4"}),_c('p',{staticClass:"font-14 bold m-0"},[_vm._v("Maraude")])]),_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{staticClass:"graph-legend bg-activite--for mr-4"}),_c('p',{staticClass:"font-14 bold m-0"},[_vm._v("PSC 1")])]),_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{staticClass:"graph-legend bg-activite--exercice mr-4"}),_c('p',{staticClass:"font-14 bold m-0"},[_vm._v("Excercice")])]),_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{staticClass:"graph-legend bg-activite--ips mr-4"}),_c('p',{staticClass:"font-14 bold m-0"},[_vm._v("IPS")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',{staticClass:"m-0 text-right text-neutral--300"},[_vm._v(" Aucune donnée de"),_c('br'),_vm._v("réponse et disponibilité ")])}]

export { render, staticRenderFns }