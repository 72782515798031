var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"www www--simple"},[_c('sidebar'),_c('div',{staticClass:"mb-64"},[_c('h1',{staticClass:"m-0 mb-8"},[_vm._v(" Bonjour "),(_vm.context && _vm.context.nom_usage)?_c('span',[_vm._v(_vm._s(_vm.context.nom_usage))]):_vm._e()]),_c('h4',{staticClass:"m-0 mb-12"},[_vm._v("Bienvenue dans l'antenne de Paris 8 & 9")]),_c('p',{staticClass:"m-0 line-height-24"},[_vm._v(" Timeline... là où une liste d’activités diverses telles que maraudes, gardes, dps et PSC1 te seront proposés. Dates et infos te sont affichés ainsi que des choix, se rendre disponible ou non. ✅ 🚫. Ton statut se mettra directement à jour. Lorsque tu t’engagera, si tu est retenu sur l’activité tu rejoindra un canal Slack spécifique par une invitation de @BOT Timeline 🤖. Si tu n’est pas retenu, tu aura un message privée cette fois ci, toujours du bot. Il sera important de répondre positivement ou négativement et de ne pas laisser une activité sans réponse 😉 Tu pourras toujours changer ton statut tant que l’activité est toujours en recensement 😊. ")]),_c('p',{staticClass:"m-0 mt-12 line-height-24"},[_vm._v(" mais pas trop vite... pour le moment, c’est encore ton onboarding, le temps d’avoir l’uniforme, le compte e-protec, etc, donc patience avant que Timeline te soit ouvert ⏰ 😎. ")])]),(_vm.onboarding)?_c('div',{staticClass:"container container--gap-2 mb-64"},[_c('h3',{staticClass:"m-0"},[_vm._v("Tes tâches")]),_c('div',{staticClass:"card card--shadow bg-white flex flex-mobile flex__align-center p-16"},[_c('div',{class:[
          'status-circle mr-24',
          _vm.onboarding.diplome && 'status-circle--check' ]},[(_vm.onboarding.diplome)?_c('span',{staticClass:"far fa-check"}):_vm._e()]),_c('div',[_c('h4',{staticClass:"m-0 mb-4"},[_vm._v("Diplôme de secouriste")]),_c('p',{staticClass:"m-0"},[_vm._v(" C'est le point primordial pour pouvoir effectuer des missions. Tu dois être en possession d'un diplôme de secouriste valide. A minima le PSC1 à defaut du PSE1. ")]),(_vm.recyclageDemande)?_c('div',[_c('hr',{staticClass:"my-16"}),_c('h5',{staticClass:"m-0 mb-4"},[_vm._v("Inscrit toi à une FC")]),_c('p',{staticClass:"m-0 mb-8 small"},[_vm._v(" Afin d'effectuer cette étape, il est nécessaire de t'inscrire à une FC. Tu peux le faire en cliquant sur le bouton ci-dessous. ")]),_c('cta',{attrs:{"variant":"white"},on:{"click":_vm.goFC}},[_vm._v(" Choisir une FC  "),_c('span',{staticClass:"fal fa-arrow-right ml-8"})])],1):_vm._e()])]),_c('div',{staticClass:"card card--shadow bg-white flex flex-mobile flex__align-center p-16"},[_c('div',{class:[
          'status-circle mr-24',
          _vm.onboarding.eprotec && 'status-circle--check' ]},[(_vm.onboarding.eprotec)?_c('span',{staticClass:"far fa-check"}):_vm._e()]),_vm._m(0)]),_c('div',{staticClass:"card card--shadow bg-white flex flex-mobile flex__align-center p-16"},[_c('div',{class:[
          'status-circle mr-24',
          _vm.onboarding.tenue && 'status-circle--check' ]},[(_vm.onboarding.tenue)?_c('span',{staticClass:"far fa-check"}):_vm._e()]),_vm._m(1)]),_c('div',{staticClass:"card card--shadow bg-white flex flex-mobile flex__align-center p-16"},[_c('div',{class:[
          'status-circle mr-24',
          _vm.onboarding.airkey && 'status-circle--check' ]},[(_vm.onboarding.airkey)?_c('span',{staticClass:"far fa-check"}):_vm._e()]),_vm._m(2)]),_c('p',{staticClass:"small m-0"},[_vm._v(" les tâches ne peuvent pas être validées par toi même. Celles-ci doivent être validées par les référents des tâches, par une personne habilitée ou bien un administrateur Timeline. ")])]):_c('div',{staticClass:"container container--gap-2"},[_c('h3',{staticClass:"m-0"},[_vm._v("Patience...")]),_vm._m(3)]),(_vm.onboarding)?_c('div',{staticClass:"container container--gap-2"},[_c('h3',{staticClass:"m-0"},[_vm._v("C'est parti ?")]),_c('div',{class:[
        'card flex flex__justify-between flex__align-center p-16',
        _vm.onboarding.diplome &&
        _vm.onboarding.tenue &&
        _vm.onboarding.eprotec &&
        _vm.onboarding.airkey
          ? 'card--border bg-info text-white'
          : 'card--disabled' ]},[_c('div',{staticClass:"flex flex-mobile flex__align-center"},[_c('div',{class:[
            'status-circle mr-24',
            _vm.onboarding.diplome &&
              _vm.onboarding.tenue &&
              _vm.onboarding.eprotec &&
              _vm.onboarding.airkey &&
              'status-circle--valid' ]},[(
              _vm.onboarding.diplome &&
              _vm.onboarding.tenue &&
              _vm.onboarding.eprotec &&
              _vm.onboarding.airkey
            )?_c('span',{staticClass:"far fa-check"}):_vm._e()]),_c('div',[_c('h4',{staticClass:"m-0 mb-4"}),(
              _vm.onboarding.diplome &&
              _vm.onboarding.tenue &&
              _vm.onboarding.eprotec &&
              _vm.onboarding.airkey
            )?_c('p',{staticClass:"m-0"},[_vm._v(" Tout est bon ! ")]):_c('p',{staticClass:"m-0"},[_vm._v(" Il reste encore quelques étapes avant de poursuivre ")])])]),_c('cta',{attrs:{"variant":"white","disabled":!_vm.onboarding.diplome ||
          !_vm.onboarding.tenue ||
          !_vm.onboarding.eprotec ||
          !_vm.onboarding.airkey},on:{"click":_vm.validOnboarding}},[_vm._v("Terminer mon onboarding")])],1),_c('p',{staticClass:"small m-0"},[_vm._v(" Cette étape est activée dès lorsque l'ensemble des tâches ci-dessus sont validées. ")])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"m-0 mb-4"},[_vm._v("Dossier d'inscription et compte e-protec")]),_c('p',{staticClass:"m-0"},[_vm._v(" Sans lui, impossible d'effectuer des missions. Dans le canal de ton intégration sur Slack tu trouveras toutes les informations nécessaires. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"m-0 mb-4"},[_vm._v("Obtenir ton uniforme")]),_c('p',{staticClass:"m-0"},[_vm._v(" Direction le maître des uniformes ! C'est avec lui que pantalon, polo et softshell t'irons comme un gant. ")]),_c('p',{staticClass:"m-0"},[_c('small',[_vm._v("Les rangers sont les seuls éléments à ta charge. Pour Le reste il t'expliquera comment obtenir ta tenue.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"m-0 mb-4"},[_vm._v("Airkey, la porte magique du local")]),_c('p',{staticClass:"m-0"},[_vm._v(" Obtient ton badge digital d'accès. Tu pourras ainsi y accéder pour chaque mission où tu devra passer par le local. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card--shadow bg-white flex flex-mobile flex__align-center p-16"},[_c('div',{staticClass:"status-circle status-circle--error mr-24"},[_c('span',{staticClass:"far fa-exclamation"})]),_c('div',[_c('h4',{staticClass:"m-0 mb-4"},[_vm._v("Ton onboarding n'est pas encore disponible")]),_c('p',{staticClass:"m-0"},[_vm._v(" Il se peut que ton compte soit tout frais, encore quelques minutes le temps que ton onboarding soit initialisé. Si cela persiste, c'est sans doute une erreur, mais pas de panique, Jordan est là pour toi. Contacte-le et tout rentrera dans l'ordre. ")])])])}]

export { render, staticRenderFns }