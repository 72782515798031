






































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Card from "@/components/card/card.vue";
import Tag from "@/components/tags/Tag.vue";
import { benevoles, login } from "@/store";
import { Benevole, User } from "@/models";

@Component({
  components: {
    Sidebar,
    Cta,
    Card,
    Tag,
  },
})
export default class Benevoles extends Vue {
  private dataReady = false;

  async mounted(): Promise<void> {
    await benevoles.benevoles();
    this.dataReady = true;
  }

  private createBenevole(): void {
    this.$router.push("/administration/benevoles/creer");
  }

  private goToUser(id: string): void {
    this.$router.push(`/administration/benevole/${id}`);
  }

  get benevoles(): Benevole[] | null {
    return benevoles.getBenevoles;
  }

  get context(): User | null {
    return login.getContext;
  }
}
