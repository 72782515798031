import { ActiviteElementKey, ElementKey, VehiculeMinimized } from "@/models";
import DataService from "@/services/data.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Data extends VuexModule {
  private _typesActivite: ActiviteElementKey[] | null = null;
  private _typesDO: ElementKey[] | null = null;
  private _lieux: ElementKey[] | null = null;
  private _vehicules: VehiculeMinimized[] | null = null;
  private _materiels: ElementKey[] | null = null;
  private _competences: ElementKey[] | null = null;
  private _roles: ElementKey[] | null = null;

  @Mutation
  private setTypesActivite(types: ActiviteElementKey[]) {
    this._typesActivite = types;
  }

  @Mutation
  private setTypesDO(types: ElementKey[]) {
    this._typesDO = types;
  }

  @Mutation
  private setLieux(lieux: ElementKey[]) {
    this._lieux = lieux;
  }

  @Mutation
  private setVehicules(vehicules: VehiculeMinimized[]) {
    this._vehicules = vehicules;
  }

  @Mutation
  private setMateriels(materiels: ElementKey[]) {
    this._materiels = materiels;
  }

  @Mutation
  private setCompetences(competences: ElementKey[]) {
    this._competences = competences;
  }

  @Mutation
  private setRoles(roles: ElementKey[]) {
    this._roles = roles;
  }

  @Action
  public async typesActivites(): Promise<void> {
    const types = await await DataService.typesActivite();
    this.setTypesActivite(types);
  }

  @Action
  public async typesDO(): Promise<void> {
    const types = await await DataService.typesDO();
    this.setTypesDO(types);
  }

  @Action
  public async vehicules(): Promise<void> {
    const vehicules = await await DataService.vehicules();
    this.setVehicules(vehicules);
  }

  @Action
  public async lieux(): Promise<void> {
    const lieux = await await DataService.lieux();
    this.setLieux(lieux);
  }

  @Action
  public async materiels(): Promise<void> {
    const materiels = await await DataService.materiels();
    this.setMateriels(materiels);
  }

  @Action
  public async competences(): Promise<void> {
    const competences = await await DataService.competences();
    this.setCompetences(competences);
  }

  @Action
  public async roles(): Promise<void> {
    const roles = await await DataService.roles();
    this.setRoles(roles);
  }

  get getTypesActivite(): ActiviteElementKey[] | null {
    return this._typesActivite;
  }

  get getTypesDO(): ElementKey[] | null {
    return this._typesDO;
  }

  get getLieux(): ElementKey[] | null {
    return this._lieux;
  }

  get getVehicules(): VehiculeMinimized[] | null {
    return this._vehicules;
  }

  get getMateriels(): ElementKey[] | null {
    return this._materiels;
  }

  get getCompetences(): ElementKey[] | null {
    return this._competences;
  }

  get getRoles(): ElementKey[] | null {
    return this._roles;
  }
}
