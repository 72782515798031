












































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import tag from "@/components/tags/Tag.vue";
import PanelOption from "@/components/option/option.vue";
import cta from "@/components/buttons/Button.vue";
import { activites, login, toast, verifAssist } from "@/store";
import { found, select } from "@/helpers";
import { Activite, LotEmplacement, LotMesVerifications, User } from "@/models";
import dayjs from "dayjs";

@Component({
  methods: {
    found,
    select,
  },
  components: {
    Sidebar,
    tag,
    PanelOption,
    cta,
  },
})
export default class VerificationAssistee extends Vue {
  private dataReady = false;
  private withActivite = false;

  private optionEmplacement = false;
  private emplacementSelected: LotEmplacement | null = null;

  async mounted(): Promise<void> {
    if (this.$route.params.activite) {
      if (!(await activites.activite(this.$route.params.activite))) {
        toast.pushError(
          "La vérification assistée n'a pas pu être réalisée à partir de l'activité demandée."
        );
      } else {
        this.withActivite = true;
      }
    }
    await verifAssist.mesVerifications();
    await verifAssist.lots();
    this.dataReady = true;
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private goToLot(id: string): void {
    if (this.withActivite) {
      this.$router.push({
        name: "VerificationAssisteeWithActiviteLot",
        params: { activite: this.$route.params.activite, lot: id },
      });
    } else {
      this.$router.push({
        name: "VerificationAssisteeLot",
        params: { lot: id },
      });
    }
  }

  private goToMesVerifs(): void {
    this.$router.push({
      name: "VerificationAssisteeMesVerifications",
    });
  }

  private openOptionEmplacement(empl: LotEmplacement): void {
    this.emplacementSelected = empl;
    this.optionEmplacement = true;
  }

  private closeOptionEmplacement(): void {
    this.optionEmplacement = false;
  }

  get context(): User | null {
    return login.getContext;
  }

  get lots(): LotEmplacement[] | null {
    return verifAssist.getLots;
  }

  get mesVerifs(): LotMesVerifications[] | null {
    return verifAssist.getMesVerifications;
  }

  get activite(): Activite | null {
    return activites.getActivite;
  }
}
