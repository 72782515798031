import { Module, VuexModule, Mutation, Action } from "vuex-class-modules";

@Module
export class Toast extends VuexModule implements IToastModule {
  private toastError: ToastMessage | null = null;
  private toastClicked = false;

  @Mutation
  private enterToastError(message: ToastMessage) {
    this.toastError = message;
  }

  @Mutation
  private enterToastClicked(bool: boolean) {
    this.toastClicked = bool;
  }

  @Action
  public async setToastError(message: ToastMessage): Promise<void> {
    this.enterToastError(message);
  }

  @Action
  public async toastIsClicked(): Promise<void> {
    this.enterToastClicked(!this.toastClicked);
  }

  public createAndPushToast(
    title: string,
    text: string,
    timeout: boolean,
    type: TYPE
  ): void {
    this.enterToastError(this.createMessage(text, title, timeout, type));
  }

  public pushError(message: string): void {
    this.enterToastError(this.createMessage(message));
  }

  private createMessage(
    message: string,
    title = "Erreur",
    timeout = true,
    type = TYPE.DANGER
  ): ToastMessage {
    return {
      actionLibelle: message,
      label: title,
      timeout: timeout,
      type: type,
    };
  }

  get toastErrorMessage(): ToastMessage | undefined {
    if (this.toastError) return this.toastError;
  }

  get toastClickedState(): boolean {
    return this.toastClicked;
  }
}

export interface IToastModule {
  toastErrorMessage: ToastMessage | undefined;
  toastClickedState: boolean;
  setToastError(error: ToastMessage): Promise<void>;
  toastIsClicked(): Promise<void>;
  createAndPushToast(
    title: string,
    label: string,
    timeout: boolean,
    type: TYPE
  ): void;
}

export interface IToastMessage {
  label: string;
  type: TYPE;
  timeout: boolean;
  actionLibelle: string;
}

export enum TYPE {
  SUCCESS = "success",
  DANGER = "danger",
  DEFAULT = "default",
  INFO = "info",
  WARNING = "warning",
}

export class ToastMessage implements IToastMessage {
  constructor(
    public label: string,
    public type: TYPE,
    public timeout: boolean,
    public actionLibelle: string
  ) {}
}
