



































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Datepicker from "@/components/forms/datepicker.vue";
import Modal from "@/components/modal/modal.vue";
import Tag from "@/components/tags/Tag.vue";
import Checkbox from "@/components/checkbox/checkbox.vue";
import { toast, data } from "@/store";
import { Activite, ActiviteElementKey, ElementKey } from "@/models";
import ActiviteService from "@/services/activite.service";
import dayjs from "dayjs";
import { mapOutActivite } from "@/helpers";

@Component({
  components: {
    Sidebar,
    Cta,
    Datepicker,
    Modal,
    Tag,
    Checkbox,
  },
})
export default class OpeActiviteCreation extends Vue {
  private modalType = false;
  private modalLibelle = false;
  private modalDate = false;
  private modalLieu = false;
  private modalOption = false;

  private optionPreSaisieVehicule = false;
  private optionPreSaisieCompetences = false;

  private activite: Activite = {
    id: null,
    libelle: null,
    debut: null,
    fin: null,
    type: { id: "0", libelle: "" },
    do: { id: "WAIT", libelle: "" },
    etat: { id: "OPEN", libelle: "" },
    lieu: null,
    description: "",
    reversion: "0",
    vehicule: null,
    parties: [],
  };

  private dataReady = false;

  async mounted(): Promise<void> {
    await data.typesActivites();
    await data.lieux();
    await data.typesDO();
    this.dataReady = true;
    this.modalType = true;
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private cancel(): void {
    this.$router.back();
  }

  private updateType(): void {
    this.modalLibelle = false;
    this.modalType = true;
  }

  private updateLibelle(): void {
    this.modalDate = false;
    this.modalLibelle = true;
  }

  private updateDate(): void {
    this.modalLieu = false;
    this.modalDate = true;
  }

  private updateLieu(): void {
    this.modalOption = false;
    this.modalLieu = true;
  }

  private validType(): void {
    this.modalType = false;
    this.modalLibelle = true;
  }

  private validLibelle(): void {
    this.modalLibelle = false;
    this.modalDate = true;
  }

  private validDate(): void {
    this.modalDate = false;
    this.modalLieu = true;
  }

  private validLieu(): void {
    this.modalLieu = false;
    this.modalOption = true;
  }

  get types(): ActiviteElementKey[] | null {
    return data.getTypesActivite;
  }

  get typesDo(): ElementKey[] | null {
    return data.getTypesDO;
  }

  get lieux(): ElementKey[] | null {
    return data.getLieux;
  }

  get today(): string {
    return dayjs().format("YYYY-MM-DD HH:mm:ss");
  }

  private selectType(selected: ActiviteElementKey): void {
    this.activite.type = selected;
  }

  private selectTypeDO(selected: ActiviteElementKey): void {
    this.activite.do = selected;
  }

  private selectLieu(selected: string): void {
    this.activite.lieu = selected;
  }

  private async createActivite(activite: Activite): Promise<void> {
    if (
      activite.libelle &&
      activite.debut &&
      activite.fin &&
      activite.do?.id !== "0" &&
      activite.type?.id !== "0"
    ) {
      const idCreate = await ActiviteService.create(
        mapOutActivite(activite),
        this.optionPreSaisieVehicule,
        this.optionPreSaisieCompetences
      );
      if (idCreate) {
        this.$router.replace(`/activite/${idCreate}`);
      }
    } else {
      toast.pushError("Veuillez remplir l'ensemble des champs obligatoire");
    }
  }
}
