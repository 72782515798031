import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import registerToServicerWorker from "./registerServiceWorker";

Vue.config.productionTip = false;

// TOASTIFICATION
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 4,
  newestOnTop: true,
  position: "bottom-center",
  timeout: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: false,
  icon: false,
  rtl: false,
});

// v-calendar
import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

// VeeValidate
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import fr from "vee-validate/dist/locale/fr.json";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  });
}
localize("fr", fr);

// DayJS
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
dayjs.locale("fr"); // use locale

// vmask
import VueMask from "v-mask";
Vue.use(VueMask);

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Flicking
import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
Vue.use(Flicking);

// Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAFHM6pD_bzI6qrbq3HFvhU1CZf2_lIZxk",
  authDomain: "pcp8---timeline.firebaseapp.com",
  projectId: "pcp8---timeline",
  storageBucket: "pcp8---timeline.appspot.com",
  messagingSenderId: "939162411796",
  appId: "1:939162411796:web:95ccf3c0542e987da41801",
  measurementId: "G-LEE4YEZQQW",
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

// Service Worker
const wb = registerToServicerWorker();
if (wb) {
  wb.addEventListener("waiting", async () => {
    await wb?.messageSW({ type: "SKIP_WAITING" });
  });
}
Vue.prototype.$workbox = wb;

export const bus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
