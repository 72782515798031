import axios, { AxiosInstance } from "axios";
import qs from "qs";
import { LocalStorageApp } from "./localstorage";

export class Http {
  static instance: AxiosInstance = axios.create({});

  static sync(): AxiosInstance {
    this.instance.defaults.maxRedirects = 2;
    this.addToken();
    return this.instance;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static qsStringify(param: any) {
    return qs.stringify(param);
  }

  static addToken(): AxiosInstance {
    this.instance.defaults.headers.common["Authorization"] =
      "Bearer " + LocalStorageApp.getItem("pcp.token");
    return this.instance;
  }

  static addPostSettings(): AxiosInstance {
    this.instance.defaults.headers.common["content-type"] =
      "application/x-www-form-urlencoded";
    return this.instance;
  }

  static withUrl(url: string): AxiosInstance {
    this.instance.defaults.baseURL = url;
    return this.instance;
  }
}
