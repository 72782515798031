






import { Component, Prop, Vue } from "vue-property-decorator";

export enum TypeAlert {
  "INFO" = "info",
  "DANGER" = "danger",
  "SUCCESS" = "success",
  "WARNING" = "warning",
  "DEFAULT" = "default",
  "DECLENCHEMENT" = "declenchement",
  "NOEL" = "noel",
}

@Component({
  components: {},
})
export default class Alert extends Vue {
  @Prop({ required: true }) type!: TypeAlert;
}
