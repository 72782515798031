import Vue from "vue";
import Vuex, { Store } from "vuex";
import { Activites } from "./modules/activite.module";

// Modules
import { Login } from "./modules/login.module";
import { Timeline } from "./modules/timeline.module";
import { Toast } from "./modules/toast.module";
import { Data } from "./modules/data.module";
import { Benevoles } from "./modules/benevole.module";
import { Vehicules } from "./modules/vehicule.module";
import { VerifAssist } from "./modules/verifAssist.module";
import { Finances } from "./modules/finance.module";
import { Profile } from "./modules/profile.module";

Vue.use(Vuex);

export const store = new Store({});
export const login = new Login({ store, name: "login" });
export const toast = new Toast({ store, name: "toast" });
export const timeline = new Timeline({ store, name: "timeline" });
export const data = new Data({ store, name: "data" });
export const activites = new Activites({ store, name: "activites" });
export const benevoles = new Benevoles({ store, name: "benevoles" });
export const vehicules = new Vehicules({ store, name: "vehicules" });
export const verifAssist = new VerifAssist({ store, name: "verifAssist" });
export const finances = new Finances({ store, name: "finances" });
export const profile = new Profile({ store, name: "profile" });
