





















































import { Component, Vue, Watch } from "vue-property-decorator";
import { POSITION } from "vue-toastification";
import { toast } from "./store";
import { ToastMessage } from "./store/modules/toast.module";
import { JwtManage } from "./utils/jwt";
import { LocalStorageApp } from "./utils/localstorage";
import Modal from "./components/modal/modal.vue";
import Cta from "./components/buttons/Button.vue";
import { bus } from "./main";

@Component({
  components: { Modal, Cta },
})
export default class App extends Vue {
  private sessionExpired = false;
  private habilitationRequired = false;
  private habilitationRequiredInformation: any | null = null;

  get toastError(): ToastMessage | undefined {
    return toast.toastErrorMessage;
  }

  created(): void {
    bus.$on("habilitationRequired", (data: any) => {
      this.habilitationRequired = data[0];
      this.habilitationRequiredInformation = data[1];
    });
  }

  mounted(): void {
    this.verifyToken();
    document.addEventListener("click", this.verifyToken);
  }

  private goToLogin(): void {
    this.$router.push("/connexion");
  }

  private switchModalHabState(): void {
    this.habilitationRequired = !this.habilitationRequired;
  }

  private verifyToken(): void {
    if (this.$route.meta?.auth) {
      const token: string | null = LocalStorageApp.getItem("pcp.token");
      if (JwtManage.isValid(token ?? "")) {
        this.sessionExpired = false;
      } else {
        this.sessionExpired = true;
      }
    } else {
      this.sessionExpired = false;
    }
  }

  @Watch("toastError")
  toastErrorListener(payload: ToastMessage): void {
    this.$toast(
      payload.actionLibelle
        ? payload.label + " — " + payload.actionLibelle
        : payload.label,
      {
        timeout: payload.timeout === false ? payload.timeout : 5000,
        toastClassName: "bg-" + payload.type,
        onClick: () => {
          toast.toastIsClicked();
        },
        position: this.$route.meta?.auth
          ? POSITION.BOTTOM_LEFT
          : POSITION.BOTTOM_CENTER,
      }
    );
  }
}
