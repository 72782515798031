














import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class Counter extends Vue {
  @Prop() value!: number;
  @Prop() max!: number;

  private val = 0;

  mounted() {
    this.val = this.value;
  }

  private plus() {
    if (this.value <= this.max) {
      this.val++;
      this.$emit("input", this.val);
    }
  }
  private minus() {
    if (this.value >= 0) {
      this.val--;
      this.$emit("input", this.val);
    }
  }

  @Watch("value")
  updateVal() {
    this.val = this.value;
  }
}
