

























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Card from "@/components/card/card.vue";
import Tabs from "@/components/tabs/Tab.vue";
import { Benevole, ElementKey } from "@/models";
import { benevoles, data } from "@/store";
import { found, select } from "@/helpers";
import BenevoleService from "@/services/benevole.service";

@Component({
  methods: {
    found,
  },
  components: {
    Sidebar,
    Cta,
    Card,
    Tabs,
  },
})
export default class BenevoleDetail extends Vue {
  private data: Benevole | null = null;
  private hasUpdated = false;

  private tabSelected = "fiche";
  private tabs = [
    { key: "fiche", label: "Fiche" },
    { key: "taches", label: "Tâches" },
    { key: "competences", label: "Compétences" },
    { key: "entretiens", label: "Entretiens" },
  ];

  async mounted(): Promise<void> {
    await this.getDatas();
    this.hasUpdated = false;
  }

  private async getDatas(): Promise<void> {
    await benevoles.benevole(this.$route.params.id);
    await data.roles();
    await data.competences();
    this.data = ({ ...benevoles.getBenevole } as Benevole) ?? null;
  }

  private selectDroit(selected: ElementKey): void {
    if (this.data) {
      this.data.role = selected.id;
    }
  }

  private async switchCompetence(selected: ElementKey): Promise<void> {
    if (this.data && this.data.id && this.data.competence) {
      this.data.competence = select(this.data.competence, selected);
    }
  }

  private async cancelUpdate(): Promise<void> {
    await this.getDatas();
    this.hasUpdated = false;
  }

  private async update(): Promise<void> {
    if (this.data) {
      await BenevoleService.update(this.data);
      await this.getDatas();
      this.hasUpdated = false;
    }
  }

  get benevole(): Benevole | null {
    return benevoles.getBenevole;
  }

  get roles(): ElementKey[] | null {
    return data.getRoles;
  }

  get competences(): ElementKey[] | null {
    return data.getCompetences;
  }

  @Watch("data.nom_usage")
  @Watch("data.role")
  @Watch("data.competence")
  private setActiteHasUpdated(): void {
    this.hasUpdated = true;
  }
}
