import { RestitFinance } from "@/models";
import FinanceService from "@/services/finance.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Finances extends VuexModule {
  private _restitutionFinanciere: RestitFinance[] | null = null;

  @Mutation
  private setRestitFinance(restitFinance: RestitFinance[]) {
    this._restitutionFinanciere = restitFinance;
  }

  @Action
  public async restitFinance(): Promise<void> {
    const restit = await await FinanceService.restitution();
    this.setRestitFinance(restit);
  }

  get getRestitFinanciere(): RestitFinance[] | null {
    return this._restitutionFinanciere;
  }
}
