import { ActiviteBesoin, ActiviteEquipeConstition, ElementKey } from "@/models";

export const found = (array: ElementKey[], search: ElementKey): boolean => {
  if (array && search) {
    return array.some((el, index) => {
      if (el && search && el.id === search.id) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
};

export const index = (array: ElementKey[], search: ElementKey): number => {
  return array.findIndex((el) => {
    if (el && search && el.id === search.id) {
      return true;
    } else {
      return false;
    }
  });
};

export const select = (
  array: ElementKey[] | ActiviteBesoin[] | ActiviteEquipeConstition[],
  value: ElementKey | ActiviteBesoin | ActiviteEquipeConstition
): ElementKey[] | ActiviteBesoin[] | ActiviteEquipeConstition[] => {
  return selectFunction(array, value);
};

export const selectBesoin = (
  array: ActiviteBesoin[],
  value: ActiviteBesoin
): ActiviteBesoin[] => {
  return selectFunction(array, value);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const selectFunction = (array: any, value: any): any => {
  const foundIndex = index(array, value);
  if (foundIndex !== -1) {
    array.splice(foundIndex, 1);
  } else {
    array.push(value);
  }
  return array;
};
