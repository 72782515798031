
































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import Tabs from "@/components/tabs/Tab.vue";
import Modal from "@/components/modal/modal.vue";
import { login, toast } from "@/store";
import { User } from "@/models";
import LoginService from "@/services/login.service";

@Component({
  components: {
    Sidebar,
    Cta,
    Tag,
    Tabs,
    Modal,
  },
})
export default class Profile extends Vue {
  private tabSelected = "profil";
  private tabs = [
    { key: "profil", label: "Profil" },
    { key: "competences", label: "Compétences" },
    { key: "habilitations", label: "Habilitations" },
  ];

  private modalUpdatePasswd = false;
  private passwdActuel = "";
  private passwdNew = "";
  private passwdNewConfirm = "";

  private openUpdatePasswd(): void {
    this.modalUpdatePasswd = true;
  }
  private closeUpdatePasswd(): void {
    this.modalUpdatePasswd = false;
    this.passwdActuel = "";
    this.passwdNew = "";
    this.passwdNewConfirm = "";
  }
  private async validUpdatePasswd(): Promise<void> {
    if (this.passwdActuel && this.passwdNew && this.passwdNewConfirm) {
      if (this.passwdNew === this.passwdNewConfirm) {
        if (this.passwdActuel !== this.passwdNew) {
          if (this.passwdNew.toLowerCase() !== "protec8") {
            if (
              await LoginService.changePassword(
                this.passwdActuel,
                this.passwdNew,
                this.passwdNewConfirm
              )
            ) {
              this.closeUpdatePasswd();
            }
          } else {
            toast.pushError(
              "Votre nouveau mot de passe ne doit pas ressembler à celui par défaut"
            );
          }
        } else {
          toast.pushError(
            "Votre nouveau mot de passe doit être différent de celui actuel"
          );
        }
      } else {
        toast.pushError(
          "Votre nouveau mot de passe n'est pas identique à la confirmation"
        );
      }
    } else {
      toast.pushError("Merci de remplir l'ensemble des champs");
    }
  }

  get context(): User | null {
    return login.getContext;
  }
}
