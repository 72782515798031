import { Benevole, ElementKey } from "@/models";
import { toast } from "@/store";
import { TYPE } from "@/store/modules/toast.module";
import { Http } from "@/utils/http";

export default class BenevoleService {
  public static async benevoles(): Promise<Benevole[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=benevoles`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async benevole(id: string): Promise<Benevole> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=benevole&id=${id}`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async update(benevole: Benevole): Promise<ElementKey> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=benevoleUpdate`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        benevole,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "OK",
        "",
        true,
        TYPE.SUCCESS
      );
    }
    return response.data?.id;
  }
}
