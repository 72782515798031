










































































































































import { Activite, User } from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";

import tag from "@/components/tags/Tag.vue";
import cta from "@/components/buttons/Button.vue";
import dayjs from "dayjs";
import { IsStarted } from "@/helpers";
import { login } from "@/store";

@Component({
  methods: {
    IsStarted,
  },
  components: { tag, cta },
})
export default class optionActivite extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) data!: Activite | null;

  private closeOption(): void {
    this.$emit("close");
  }

  private openModification(): void {
    this.$router.push(`/activite/${this.data?.id}`);
  }

  private openVerification(activiteId: string): void {
    this.$router.push({
      name: "VerificationAssisteeWithActivite",
      params: { activite: activiteId },
    });
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  get context(): User | null {
    return login.getContext;
  }
}
