import { Vehicule } from "@/models";
import { Http } from "@/utils/http";

export default class VehiculeService {
  public static async vehicules(): Promise<Vehicule[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=vehiculesInformations`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
  public static async stationServices(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=vehiculesStationsServices`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
}
