













































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Tag from "@/components/tags/Tag.vue";
import Cta from "@/components/buttons/Button.vue";
import { Activite, ActiviteRecensementState } from "@/models";
import { isSameDay } from "@/helpers";
import dayjs from "dayjs";

@Component({
  components: { Tag, Cta },
  methods: {
    isSameDay,
  },
})
export default class CardActivite extends Vue {
  @Prop({ required: true }) activite!: Activite | null;

  private setRecensement(
    state: ActiviteRecensementState,
    act = this.activite?.id
  ): void {
    this.$emit("setRecensement", act ?? "", state);
  }

  get ActiviteRecensementState(): any {
    return ActiviteRecensementState;
  }

  get personnesEngages(): string {
    if (this.activite && this.activite.recensement) {
      if (
        this.activite.recensement.length &&
        this.activite.recensement.filter((r) => r.etat.id === "ENGAGE").length
      ) {
        return this.activite.recensement
          .filter((r) => r.etat.id === "ENGAGE")
          .map((r) => {
            return r.nom_usage;
          })
          .join(", ");
      } else {
        return "Aucune personne disponible";
      }
    } else {
      return "Informations non disponible";
    }
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private openSidebar(): void {
    this.$emit("sidebarActivite", this.activite?.id);
  }
}
