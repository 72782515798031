import { toast } from "@/store";
import { TYPE } from "@/store/modules/toast.module";
import { Http } from "@/utils/http";
import { LocalStorageApp } from "@/utils/localstorage";

export default class LoginService {
  public static async login(
    username: string,
    passwd: string
  ): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_AUTH}/?task=login`;
    try {
      const response = await Http.instance
        .post(targetUrl, {
          username: username,
          passwd: passwd,
        })
        .then(async (result: any) => {
          if (result.data.data.token) {
            await LocalStorageApp.setItem("pcp.token", result.data.data.token);
            await Http.sync();
          }
          return true;
        })
        .catch((error: any) => {
          toast.createAndPushToast(
            `${error.response.data.message}`,
            "",
            true,
            TYPE.DANGER
          );
          return false;
        });
      return response;
    } catch (err) {
      toast.createAndPushToast(
        `Une erreur s'est produite`,
        "Veuillez réessayer plus tard",
        true,
        TYPE.DANGER
      );
      return false;
    }
  }

  public static async changePassword(
    passwdOld: string,
    passwdNew: string,
    passwdConfirm: string
  ): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=updatePassword`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        passwdOld,
        passwdNew,
        passwdConfirm,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "Mot de passe modifié",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async codeSlack(username: string): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_AUTH}/?task=slack`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        username,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      toast.createAndPushToast(
        response.message ?? "Code de connexion envoyé sur Slack",
        "",
        true,
        TYPE.SUCCESS
      );
      return true;
    }
    return false;
  }

  public static async context(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=context`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async onboarding(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=onboarding`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async onboardingValidation(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=onboardingValidation`;
    const { data: response } = await Http.instance.post(targetUrl);
    return response.success;
  }

  public static async contextUnlock(user: string): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_AUTH}/?task=context&id=${user}`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
}
