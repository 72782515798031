export class LocalStorageApp {
  public static clear(): void {
    localStorage.clear();
  }

  public static getItem<T>(key: string): T | null {
    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key) || "")
      : null;
  }

  public static setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
