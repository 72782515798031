import { UserCalendrier } from "@/models";
import { toast } from "@/store";
import { Http } from "@/utils/http";

export default class ProfileService {
  public static async updateParamCalendrier(settings: UserCalendrier): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=updateCalendrier`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        retenu: settings.retenu ? 1 : 0,
        provisoire: settings.provisoire ? 1 : 0,
        busy: settings.busy ? 1 : 0,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
      return false;
    }
    if (response.success) {
      return true;
    }
    return false;
  }
}
