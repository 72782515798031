
























































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import Tabs from "@/components/tabs/Tab.vue";
import PanelOption from "@/components/option/option.vue";
import { login, verifAssist } from "@/store";
import { LotMesVerifications, User } from "@/models";
import { isSameDay } from "@/helpers";
import dayjs from "dayjs";

@Component({
  components: {
    Sidebar,
    Cta,
    Tag,
    Tabs,
    PanelOption,
  },
  methods: {
    isSameDay,
  },
})
export default class TechVerifications extends Vue {
  private dataReady = false;

  private optionVerif = false;
  private optionVerifUUID = "";
  private verifSelected: LotMesVerifications | null = null;
  
  private optionExpired = false;
  private optionExpiredExtend = false;

  private autorefresh: number | null = null;

  async mounted(): Promise<void> {
    this.updateVerifsList();
    this.autorefresh = setInterval(this.updateVerifsList, 5000);
    this.dataReady = true;
  }

  destroyed(): void {
    clearInterval(this.autorefresh as number);
  }

  async updateVerifsList(): Promise<void> {
    await verifAssist.verifications();
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private openVerification(uuid: string): void {
    this.optionVerifUUID = uuid;
    this.updateViewVerif();
    this.optionVerif = true;
    if (this.optionExpired) {
      this.optionExpiredExtend = true;
    }
  }

  private closeVerification(): void {
    this.optionVerif = false;
    this.verifSelected = null;
    if (this.optionExpired) {
      this.optionExpiredExtend = false;
    }
  }

  private openExpired(): void {
    this.optionExpired = true;
  }

  private closeExpired(): void {
    this.optionExpired = false;
  }

  get allVerifs(): LotMesVerifications[] | null {
    return verifAssist.getVerifications ?? null;
  }

  get verifs(): LotMesVerifications[] | null {
    return (
      verifAssist.getVerifications?.filter(
        (verif) => verif.statut !== "EXPIRE"
      ) ?? null
    );
  }

  get verifsExpired(): LotMesVerifications[] | null {
    return (
      verifAssist.getVerifications?.filter(
        (verif) => verif.statut === "EXPIRE"
      ) ?? null
    );
  }

  get context(): User | null {
    return login.getContext;
  }

  @Watch("allVerifs")
  updateViewVerif(): void {
    if (this.allVerifs && this.optionVerifUUID) {
      this.verifSelected = this.allVerifs?.find(
        (verif) => verif.session === this.optionVerifUUID
      ) as LotMesVerifications;
      this.verifSelected.verification.sort((a, b) =>
        a.statut > b.statut ? 1 : -1
      );
    }
  }
}
