import dayjs from "dayjs";
import jwt_decode, { JwtPayload } from "jwt-decode";

interface JwtPayloadPCP extends JwtPayload {
  type?: string;
  user_id: string;
  user_username: string;
  user_role: string;
}

export class JwtManage {
  public static decode(token: string): JwtPayloadPCP | null {
    try {
      return jwt_decode(token);
    } catch (error) {
      return null;
    }
  }
  public static isValid(token: string): boolean {
    const decode = this.decode(token);
    if (
      decode &&
      decode.exp &&
      decode.exp > dayjs().unix() &&
      decode &&
      decode.type &&
      decode.type === "timeline"
    ) {
      return true;
    }
    return false;
  }
}
