












































import { Component, Prop, Vue } from "vue-property-decorator";
import Tag from "@/components/tags/Tag.vue";
import Cta from "@/components/buttons/Button.vue";
import { Activite, ActiviteRecensementState } from "@/models";
import { isSameDay } from "@/helpers";
import dayjs from "dayjs";

@Component({
  components: { Tag, Cta },
  methods: {
    isSameDay,
  },
})
export default class CardActiviteOpe extends Vue {
  @Prop({ required: true }) activite!: Activite | null;

  private setRecensement(state: ActiviteRecensementState): void {
    this.$emit("setRecensement", this.activite?.id ?? "", state);
  }

  get ActiviteRecensementState(): any {
    return ActiviteRecensementState;
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private goToActivite(id: string) {
    this.$router.push(`/activite/${id}`);
  }
}
