













































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import { timeline } from "@/store";

@Component({
  components: {
    Sidebar,
  },
})
export default class StatistiquesActivites extends Vue {
  async mounted(): Promise<void> {
    await timeline.statistiques();
  }

  get Statistiques(): any | null {
    return timeline.getStatistiques;
  }
}
