import dayjs from "dayjs";

export const isSameDay = (debut: string, fin: string): boolean => {
  const dateDebut = dayjs(debut).format("DD/MM/YYYY");
  const dateFin = dayjs(fin).format("DD/MM/YYYY");
  return dateDebut === dateFin;
};

export const isInFuture = (date: string): boolean => {
  return dayjs(date).isAfter(dayjs());
};

export const IsStarted = (date: string): boolean => {
  return dayjs(dayjs()).isBefore(dayjs(date).add(6, "hour"));
};
