







































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import CardProchaineActivite from "@/components/card/cardProchaineActivite.vue";
import panelActivite from "@/components/panel/panelActivite.vue";
import Tabs from "@/components/tabs/Tab.vue";
// import { activites, timeline } from "@/store";
// import { Activite, MonSuiviActivite } from "@/models";

@Component({
  components: {
    Sidebar,
    CardProchaineActivite,
    panelActivite,
    Tabs,
  },
})
export default class MonActivite extends Vue {
  // private panelActivite = false;
  // private panelActiviteData: Activite | null = null;

  // private tabSelected = "dashboard";
  // private tabs = [
  //   { key: "dashboard", label: "Vue d'ensemble" },
  //   { key: "historique", label: "Mon historique" },
  // ];

  // async mounted(): Promise<void> {
    // await timeline.monActivite();
  // }

  // private async openSidebar(id: string): Promise<void> {
  //   this.panelActivite = true;
  //   if (await activites.activite(id)) {
  //     this.panelActiviteData = activites.getActivite;
  //   }
  // }

  // private closePanel(): void {
  //   this.panelActivite = false;
  //   this.panelActiviteData = null;
  // }

  // get monActivite(): MonSuiviActivite | null {
  //   return timeline.getMonActivite;
  // }
}
