import { Vehicule } from "@/models";
import VehiculeService from "@/services/vehicule.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Vehicules extends VuexModule {
  private _vehicules: Vehicule[] | null = null;
  private _stationsServices: any | null = null;

  @Mutation
  private setVehicules(vehicules: Vehicule[]) {
    this._vehicules = vehicules;
  }
  @Mutation
  private setStations(stationsServices: any) {
    this._stationsServices = stationsServices;
  }

  @Action
  public async vehicules(): Promise<void> {
    const vehicules = await await VehiculeService.vehicules();
    this.setVehicules(vehicules);
  }

  @Action
  public async stationsServices(): Promise<void> {
    const stations = await await VehiculeService.stationServices();
    this.setStations(stations);
  }

  get getVehicules(): Vehicule[] | null {
    return this._vehicules;
  }

  get getStationsServices(): any | null {
    return this._stationsServices;
  }
}
