import { OnboardingSteps, User } from "@/models";
import LoginService from "@/services/login.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Login extends VuexModule {
  private _context: User | null = null;
  private _onboarding: OnboardingSteps | null = null;

  @Mutation
  private setContext(information: any) {
    this._context = information;
  }
  @Mutation
  private setOnboarding(information: any) {
    this._onboarding = information;
  }

  @Action
  async login(payload: any): Promise<boolean> {
    try {
      await LoginService.login(payload.username, payload.passwd);
      return true;
    } catch {
      return false;
    }
  }

  @Action
  public async context(): Promise<void> {
    const user = await await LoginService.context();
    this.setContext(user);
  }

  @Action
  public async onboarding(): Promise<void> {
    const onboarding = await await LoginService.onboarding();
    this.setOnboarding(onboarding);
  }

  get getContext(): User | null {
    return this._context;
  }

  get getOnboarding(): OnboardingSteps | null {
    return this._onboarding;
  }
}
