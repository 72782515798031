import {
  LotDetail,
  LotEmplacement,
  LotMesVerifications,
  LotVerification,
} from "@/models";
import VerifAssistService from "@/services/verifAssist.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class VerifAssist extends VuexModule {
  private _lots: LotEmplacement[] | null = null;
  private _lot: LotDetail | null = null;
  private _mesVerifs: LotMesVerifications[] | null = null;
  private _verifications: LotMesVerifications[] | null = null;

  @Mutation
  private setLots(lots: LotEmplacement[]) {
    this._lots = lots;
  }
  @Mutation
  private setLot(lot: LotDetail) {
    this._lot = lot;
  }
  @Mutation
  private setMesVerif(verifs: LotMesVerifications[]) {
    this._mesVerifs = verifs;
  }
  @Mutation
  private setAllVerifs(verifs: LotMesVerifications[]) {
    this._verifications = verifs;
  }

  @Action
  public async lots(): Promise<void> {
    const lots = await VerifAssistService.lots();
    this.setLots(lots);
  }

  @Action
  public async lot(id: string): Promise<boolean> {
    const lot = await VerifAssistService.lot(id);
    if (lot) {
      this.setLot(lot);
      return true;
    } else {
      return false;
    }
  }

  @Action
  public async mesVerifications(session?: string): Promise<boolean> {
    const verif = await VerifAssistService.mesVerifications(session);
    this.setMesVerif(verif);
    if (verif) {
      return true;
    } else {
      return false;
    }
  }

  @Action
  public async verifications(session?: string): Promise<boolean> {
    const verif = await VerifAssistService.verifications(session);
    this.setAllVerifs(verif);
    if (verif) {
      return true;
    } else {
      return false;
    }
  }

  @Action
  public async addVerif(verifSave: LotVerification): Promise<boolean> {
    if (verifSave.session && verifSave.uuid && verifSave.lot) {
      const lot = await VerifAssistService.addVerif(
        verifSave.session,
        verifSave.uuid,
        verifSave.lot,
        verifSave.quantite,
        verifSave.statut_electronique,
        verifSave.activite
      );
      if (lot) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get getLots(): LotEmplacement[] | null {
    return this._lots;
  }

  get getLot(): LotDetail | null {
    return this._lot;
  }

  get getMesVerifications(): LotMesVerifications[] | null {
    return this._mesVerifs;
  }

  get getVerifications(): LotMesVerifications[] | null {
    return this._verifications;
  }
}
