export enum userRole {
  "USER" = "USER",
  "OPE" = "OPE",
  "ADMIN" = "ADMIN",
}

export interface User {
  id: string;
  username: string;
  nom_usage: string;
  telephone: string | null;
  preferences: UserPreferences;
  jopStarted: boolean;
  role: userRole;
  roles: string[];
  competences: string[];
  habilitations: string[];
  onboarding: boolean;
  api_key?: string;
  slack: {
    id: string;
    connected: boolean;
    image: string;
    image_big: string;
  };
}

export interface UserPreferences {
  calendrier: UserCalendrier;
  declenchements: {
    sms: boolean;
  };
}

export interface UserCalendrier {
  retenu: boolean;
  provisoire: boolean;
  busy: boolean;
}

export interface OnboardingSteps {
  diplome: boolean;
  tenue: boolean;
  eprotec: boolean;
  airkey: boolean;
}
