import { Benevole } from "@/models";
import BenevoleService from "@/services/benevole.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Benevoles extends VuexModule {
  private _benevoles: Benevole[] | null = null;
  private _benevole: Benevole | null = null;

  @Mutation
  private setBenevoles(benevoles: Benevole[]) {
    this._benevoles = benevoles;
  }

  @Mutation
  private setBenevole(benevole: Benevole) {
    this._benevole = benevole;
  }

  @Action
  public async benevoles(): Promise<void> {
    const benevoles = await await BenevoleService.benevoles();
    this.setBenevoles(benevoles);
  }

  @Action
  public async benevole(id: string): Promise<void> {
    const benevole = await await BenevoleService.benevole(id);
    this.setBenevole(benevole);
  }

  get getBenevoles(): Benevole[] | null {
    return this._benevoles;
  }

  get getBenevole(): Benevole | null {
    return this._benevole;
  }
}
