var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}]},[(!_vm.context.onboarding)?_c('div',{staticClass:"navigation__mobile-action"},[_c('router-link',{staticClass:"element",attrs:{"to":"/timeline"}},[_c('span',{staticClass:"fal fa-check-circle"}),_c('span',{staticClass:"text"},[_vm._v("Timeline")])]),_c('router-link',{staticClass:"element",attrs:{"to":"/verification-assistee"}},[_c('span',{staticClass:"fal fa-backpack"}),_c('span',{staticClass:"text"},[_vm._v("Vérif")])]),_c('div',{staticClass:"element",on:{"click":_vm.switchState}},[_c('span',{staticClass:"fal fa-bars"}),_c('span',{staticClass:"text"},[_vm._v("Menu")])])],1):_vm._e(),_c('div',{class:['navigation', _vm.state && 'navigation--open']},[_vm._m(0),_c('div',{staticClass:"navigation__nav"},[_c('div',{staticClass:"navigation__nav__scroll"},[(!_vm.context.onboarding)?_c('div',{staticClass:"navigation__part"},[_c('router-link',{attrs:{"to":"/timeline"}},[_c('div',[_c('span',{staticClass:"far fa-check-circle"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Timeline")])])]),_c('router-link',{attrs:{"to":"/mon-activite"}},[_c('div',[_c('span',{staticClass:"far fa-bolt"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Mon activité")])])]),_c('router-link',{attrs:{"to":"/mon-calendrier"}},[_c('div',[_c('span',{staticClass:"far fa-calendar-alt"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Mon calendrier")])])]),_c('router-link',{attrs:{"to":"/verification-assistee"}},[_c('div',[_c('span',{staticClass:"far fa-backpack"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Vérification assistée")])])]),_c('router-link',{attrs:{"to":"/mon-profil"}},[_c('div',[_c('span',{staticClass:"far fa-user"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Mon profil")])])])],1):_vm._e(),(
            _vm.context && _vm.context.roles.includes('BOARD') && !_vm.context.onboarding
          )?_c('div',[_c('hr'),_c('div',{staticClass:"navigation__part"},[_c('p',{staticClass:"navigation__part__label navigation--nocompact"},[_vm._v(" Vue d'ensemble ")]),_c('router-link',{attrs:{"to":"/tableau-de-bord"}},[_c('div',[_c('span',{staticClass:"far fa-stream"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Tableau de bord")])])])],1)]):_vm._e(),(
            _vm.context && _vm.context.roles.includes('OPE') && !_vm.context.onboarding
          )?_c('div',[_c('hr'),_c('div',{staticClass:"navigation__part"},[_c('p',{staticClass:"navigation__part__label navigation--nocompact"},[_vm._v("OPE")]),_c('router-link',{attrs:{"to":"/activites/creer"}},[_c('div',[_c('span',{staticClass:"far fa-plus-circle"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Nouvelle activité")])])]),_c('router-link',{attrs:{"to":"/activites/gestions"}},[_c('div',[_c('span',{staticClass:"far fa-box-open"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Gestions des activités")])])])],1)]):_vm._e(),(
            !_vm.context.onboarding &&
            (_vm.context.roles.includes('OPE') || _vm.context.roles.includes('TECH'))
          )?_c('div',[_c('hr'),_c('div',{staticClass:"navigation__part"},[_c('p',{staticClass:"navigation__part__label navigation--nocompact"},[_vm._v("TECH")]),(_vm.context && _vm.context.roles.includes('OPE'))?_c('router-link',{attrs:{"to":"/tech/vehicules"}},[_c('div',[_c('span',{staticClass:"far fa-ambulance"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Véhicules")])])]):_vm._e(),(_vm.context && _vm.context.roles.includes('TECH'))?_c('router-link',{attrs:{"to":"/tech/verifications"}},[_c('div',[_c('span',{staticClass:"far fa-backpack"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Vérifications")])])]):_vm._e()],1)]):_vm._e(),(
            _vm.context && _vm.context.roles.includes('NOUB') && !_vm.context.onboarding
          )?_c('div',[_c('hr'),_c('div',{staticClass:"navigation__part"},[_c('p',{staticClass:"navigation__part__label navigation--nocompact"},[_vm._v("NOUB")]),_c('router-link',{attrs:{"to":"/administration/benevoles"}},[_c('div',[_c('span',{staticClass:"far fa-users"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Bénévoles")])])])],1)]):_vm._e(),(
            _vm.context && _vm.context.roles.includes('ADMIN') && !_vm.context.onboarding
          )?_c('div',[_c('hr'),_c('div',{staticClass:"navigation__part"},[_c('p',{staticClass:"navigation__part__label navigation--nocompact"},[_vm._v("ADMIN")]),_c('router-link',{attrs:{"to":"/administration/parametrages"}},[_c('div',[_c('span',{staticClass:"far fa-sliders-v"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Paramétrages")])])]),(_vm.context && _vm.context.roles.includes('STATS_GLB'))?_c('router-link',{attrs:{"to":"/statistiques"}},[_c('div',[_c('span',{staticClass:"far fa-chart-pie"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Statistiques")])])]):_vm._e(),(_vm.context && _vm.context.roles.includes('FINC_COMPT'))?_c('router-link',{attrs:{"to":"/finances"}},[_c('div',[_c('span',{staticClass:"far fa-receipt"}),_c('span',{staticClass:"text navigation--nocompact"},[_vm._v("Finances")])])]):_vm._e()],1)]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation__product"},[_c('div',{staticClass:"flex flex__align-center"},[_c('img',{attrs:{"src":require("@/assets/images/logos/protectioncivile.png"),"alt":"Logo Protection Civile"}}),_c('div',{staticClass:"navigation--nocompact ml-12"},[_c('p',{staticClass:"bold font-18 m-0"},[_vm._v("Timeline")]),_c('p',{staticClass:"small m-0"},[_vm._v("Protection Civile Paris 8 et 9")])])])])}]

export { render, staticRenderFns }