import { Activite } from "@/models";
import ActiviteService from "@/services/activite.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Activites extends VuexModule {
  private _activite: Activite | null = null;

  @Mutation
  private setActivite(activite: Activite) {
    this._activite = activite;
  }

  @Action
  public async activite(id: string): Promise<boolean> {
    const activite = await ActiviteService.activite(id);
    if (activite) {
      this.setActivite(activite);
      return true;
    } else {
      return false;
    }
  }

  get getActivite(): Activite | null {
    return this._activite;
  }
}
