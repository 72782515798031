




























import dayjs from "dayjs";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tag from "@/components/tags/Tag.vue";
import { ProchaineActivite } from "@/models";

@Component({
  components: { Tag },
})
export default class CardCalendrier extends Vue {
  @Prop({ required: true }) calendrier!: ProchaineActivite[] | null;

  private libelleDate(dayAjout: number): string {
    return dayjs().add(dayAjout, "day").format("DD/MM");
  }

  private activitesDay(
    activites: ProchaineActivite[],
    date: string
  ): ProchaineActivite[] {
    return activites.filter((a) => dayjs(a.debut).format("DD/MM") === date);
  }
}
