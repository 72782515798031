import { toast } from "./../store/index";
import { LotDetail, LotEmplacement, LotMesVerifications } from "@/models";
import { Http } from "@/utils/http";
import { TYPE } from "@/store/modules/toast.module";

export default class VerifAssistService {
  public static async lots(): Promise<LotEmplacement[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=lots`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async lot(id: string): Promise<LotDetail> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=lot&id=${id}`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async mesVerifications(
    session?: string
  ): Promise<LotMesVerifications[]> {
    const targetUrl = `${
      process.env.VUE_APP_API_APP
    }/?task=lotMesVerifications${session ? "&session=" + session : ""}`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async verifications(
    session?: string
  ): Promise<LotMesVerifications[]> {
    const targetUrl = `${
      process.env.VUE_APP_API_APP
    }/?task=verifications${session ? "&session=" + session : ""}`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async addVerif(
    session: string,
    uuid: number,
    lot: string,
    quantite: number,
    statut_electronique?: boolean,
    activite?: string
  ): Promise<boolean> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=lotVerification`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        session: session,
        lot: lot,
        activite: activite,
        materiel: {
          uuid: uuid,
          qte: quantite,
          statut_electronique: statut_electronique,
        },
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (!response.error) {
      return true;
    }
    return response.data;
  }
}
