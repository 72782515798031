var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    ("cta cta--" + _vm.variant),
    _vm.block && 'cta--block',
    _vm.small && 'cta--small',
    _vm.large && 'cta--large',
    _vm.round && 'cta--round',
    _vm.disabled && 'cta--disabled',
    _vm.selectable && 'cta--selectable',
    _vm.checkable && 'cta--checkable',
    _vm.checkable && 'cta--checkable',
    (_vm.selectable || _vm.checkable) && _vm.active && 'cta--active' ],on:{"click":function($event){!_vm.disabled && _vm.$emit('click')}}},[(_vm.selectable)?_c('span',{staticClass:"cta__selectabled"}):_vm._e(),(_vm.checkable)?_c('span',{staticClass:"cta__checkable"}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }