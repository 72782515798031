import { RestitFinance } from "@/models";
import { Http } from "@/utils/http";

export default class FinanceService {
  public static async restitution(): Promise<RestitFinance[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=restitutionFinanciere`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
}
