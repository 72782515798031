







































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import { finances } from "@/store";
import { RestitFinance } from "@/models";
import dayjs from "dayjs";

@Component({
  components: {
    Sidebar,
    Cta,
    Tag,
  },
})
export default class RestitutionFinanciere extends Vue {
  async mounted() {
    await finances.restitFinance();
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  get restit(): RestitFinance[] | null {
    return finances.getRestitFinanciere;
  }
}
