










import { Component, Prop, Vue } from "vue-property-decorator";

export enum TypeModal {
  "DEFAULT" = "default",
  "LARGE" = "large",
}

@Component({
  components: {},
})
export default class Modal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ default: TypeModal.DEFAULT }) type?: TypeModal;
}
