











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Switch extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop() label?: string;

  private switchValue(): void {
    this.$emit("input", !this.value);
  }
}
