import {
  Activite,
  ActiviteRecensementState,
  ElementKey,
  ProchaineActivite,
  MonSuiviActivite,
  JopContext,
} from "@/models";
import { toast } from "@/store";
import { TYPE } from "@/store/modules/toast.module";
import { Http } from "@/utils/http";

export default class TimelineService {
  public static async prochaineActivite(): Promise<ProchaineActivite[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=prochainesActivites`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
  public static async monActivite(): Promise<MonSuiviActivite> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=mesActivites`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async statistiques(): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=statistiques`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async activites(types: ElementKey[]): Promise<Activite[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activites`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        types,
      })
    );
    return response.data;
  }

  public static async activitesList(): Promise<Activite[]> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=activitesList`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }

  public static async recensement(
    activite: string,
    etat: ActiviteRecensementState,
    user_id?: string,
    toastResult = false
  ): Promise<any> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=recensement`;
    await Http.addPostSettings();
    const { data: response } = await Http.instance.post(
      targetUrl,
      Http.qsStringify({
        activite,
        etat,
        user_id,
      })
    );
    if (response.error) {
      toast.pushError(response.message ?? "Une erreur s'est produite.");
    }
    if (toastResult && !response.error) {
      toast.createAndPushToast(
        "Recensement",
        response.message,
        true,
        TYPE.DEFAULT
      );
      return true;
    }
    return response.data;
  }

  public static async jop(): Promise<JopContext> {
    const targetUrl = `${process.env.VUE_APP_API_APP}/?task=jopContext`;
    const { data: response } = await Http.instance.get(targetUrl);
    return response.data;
  }
}
