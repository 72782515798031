import { Workbox } from "workbox-window";

const registerToServicerWorker = (): Workbox | null => {
  let wb: Workbox | null = null;
  if ("serviceWorker" in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    wb.addEventListener("controlling", () => {
      window.location.reload();
    });

    wb.register();
  } else {
    wb = null;
  }
  return wb;
};

export default registerToServicerWorker;
