

























import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import CardProchaineActivite from "@/components/card/cardProchaineActivite.vue";
import panelActivite from "@/components/panel/panelActivite.vue";

@Component({
  components: {
    Sidebar,
    CardProchaineActivite,
    panelActivite,
  },
})
export default class LocalTenues extends Vue {}
