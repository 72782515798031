

















































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import cta from "@/components/buttons/Button.vue";
import tag from "@/components/tags/Tag.vue";
import PanelOption from "@/components/option/option.vue";
import Counter from "@/components/forms/counter.vue";
import checkbox from "@/components/checkbox/checkbox.vue";
import { activites, login, toast, verifAssist } from "@/store";
import { found, select } from "@/helpers";
import {
  Activite,
  LotDetail,
  LotMateriel,
  LotMesVerifications,
  LotMesVerificationsVerification,
  User,
} from "@/models";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

@Component({
  methods: {
    found,
    select,
  },
  components: {
    Sidebar,
    cta,
    tag,
    PanelOption,
    Counter,
    checkbox,
  },
})
export default class VerificationAssisteeLot extends Vue {
  private dataReady = false;
  private withActivite = false;
  private PanelOptionState = false;
  private categorieFocus = 0;
  private materielFocus = -1;
  private counterFocus = 0;
  private electroniqueIsOkFocus = true;
  private nextIsAvailable = true;
  private isFocused = false;

  private uuid_session = uuidv4();

  async mounted(): Promise<void> {
    if (this.$route.params.lot) {
      if (this.$route.params.activite) {
        if (!(await activites.activite(this.$route.params.activite))) {
          toast.pushError(
            "La vérification assistée n'a pas pu être réalisée à partir de l'activité demandée."
          );
        } else {
          this.withActivite = true;
        }
      }
      await verifAssist.lot(this.$route.params.lot);
      await verifAssist.mesVerifications(this.uuid_session ?? undefined);
    }
    this.dataReady = true;
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private startVerif(): void {
    this.PanelOptionState = true;
  }

  private closeVerifAssist(): void {
    this.PanelOptionState = false;
    this.categorieFocus = 0;
    this.materielFocus = -1;
    this.counterFocus = 0;
    this.electroniqueIsOkFocus = true;
  }

  private verifyElement(materiel: LotMateriel, index: number, indexCat: number): void {
    if (materiel.quantite > 0) {
      this.categorieFocus = indexCat;
      this.materielFocus = index;
      this.counterFocus = materiel.quantite;
      this.electroniqueIsOkFocus = true;
      this.PanelOptionState = true;
      this.isFocused = true;
    }
  }

  private verifNext(): void {
    if (this.lot) {
      if (
        this.lot.categories[this.categorieFocus].materiels.length - 1 >
        this.materielFocus
      ) {
        this.materielFocus++;
        this.counterFocus =
          this.lot.categories[this.categorieFocus].materiels[
            this.materielFocus
          ].quantite;
        this.electroniqueIsOkFocus = true;
      } else if (this.lot.categories.length - 1 > this.categorieFocus) {
        this.categorieFocus++;
        this.materielFocus = -1;
        this.electroniqueIsOkFocus = true;
      } else {
        this.categorieFocus = -1;
        this.electroniqueIsOkFocus = true;
      }
    }
  }

  private async saveVerif(
    session: string,
    uuid: number,
    count: number,
    statutElectronique: boolean | null,
    closePanel?: boolean
  ): Promise<void> {
    this.nextIsAvailable = false;
    if (
      await verifAssist.addVerif({
        session: session,
        lot: this.$route.params.lot,
        activite: this.$route.params.activite ?? undefined,
        uuid: uuid,
        quantite: count,
        statut_electronique: statutElectronique ?? undefined,
      })
    ) {
      await verifAssist.mesVerifications(this.uuid_session ?? undefined);
      if (closePanel) {
        this.isFocused = false;
        this.closeVerifAssist();
      } else {
        this.verifNext();
      }
    }
    this.nextIsAvailable = true;
  }

  private checkVerif(uuid: string): boolean | LotMesVerificationsVerification {
    if (this.mesVerifs && this.mesVerifs[0].session == this.uuid_session) {
      return this.mesVerifs[0].verification.filter(
        (v) => v.materiel.uuid == uuid
      )[0];
    } else {
      return false;
    }
  }

  get context(): User | null {
    return login.getContext;
  }

  get lot(): LotDetail | null {
    return verifAssist.getLot;
  }

  get mesVerifs(): LotMesVerifications[] | null {
    return verifAssist.getMesVerifications;
  }

  get activite(): Activite | null {
    return activites.getActivite;
  }
}
