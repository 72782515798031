import {
  Activite,
  payloadTimeline,
  ProchaineActivite,
  MonSuiviActivite,
  JopContext,
} from "@/models";
import TimelineService from "@/services/timeline.service";
import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

@Module
export class Timeline extends VuexModule {
  private _prochaineActivite: ProchaineActivite[] | null = null;
  private _monActivite: MonSuiviActivite | null = null;
  private _activites: Activite[] | null = null;
  private _activitesList: Activite[] | null = null;
  private _statistiques: any | null = null;
  private _jop: JopContext | null = null;

  @Mutation
  private setProchaineActivite(activite: ProchaineActivite[]) {
    this._prochaineActivite = activite;
  }

  @Mutation
  private setMonActivite(activites: MonSuiviActivite) {
    this._monActivite = activites;
  }

  @Mutation
  private setStatistiques(statistiques: any) {
    this._statistiques = statistiques;
  }

  @Mutation
  private setActivites(activites: Activite[]) {
    this._activites = activites;
  }

  @Mutation
  private setActivitesList(activites: Activite[]) {
    this._activitesList = activites;
  }

  @Mutation
  private setJop(jop: JopContext) {
    this._jop = jop;
  }

  @Action
  public async prochaineActivite(): Promise<void> {
    const activite = await await TimelineService.prochaineActivite();
    this.setProchaineActivite(activite);
  }

  @Action
  public async monActivite(): Promise<void> {
    const activites = await await TimelineService.monActivite();
    this.setMonActivite(activites);
  }

  @Action
  public async statistiques(): Promise<void> {
    const statistiques = await await TimelineService.statistiques();
    this.setStatistiques(statistiques);
  }

  @Action
  public async activites(payload: payloadTimeline): Promise<void> {
    const activites = await await TimelineService.activites(payload.types);
    if (payload.list === "ALL") {
      this.setActivites(activites);
    } else if (payload.list === "NON_REPONDU") {
      this.setActivites(
        activites.filter(
          (a) =>
            a.info_secouriste?.statut === payload.list && a.etat?.id === "OPEN"
        )
      );
    } else {
      this.setActivites(
        activites.filter((a) => a.info_secouriste?.statut === payload.list)
      );
    }
  }

  @Action
  public async activitesList(): Promise<void> {
    const activites = await await TimelineService.activitesList();
    this.setActivitesList(activites);
  }

  @Action
  public async jop(): Promise<void> {
    const jop = await await TimelineService.jop();
    this.setJop(jop);
  }

  get getProchaineActivite(): ProchaineActivite[] | null {
    return this._prochaineActivite;
  }

  get getMonActivite(): MonSuiviActivite | null {
    return this._monActivite;
  }

  get getStatistiques(): any | null {
    return this._statistiques;
  }

  get getActivites(): Activite[] | null {
    return this._activites;
  }

  get getActivitesList(): Activite[] | null {
    return this._activitesList;
  }

  get getJop(): JopContext | null {
    return this._jop;
  }
}
