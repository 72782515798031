













































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import CardActivite from "@/components/card/cardActivite.vue";
import CardActiviteOpe from "@/components/card/cardActiviteOpe.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import Tabs from "@/components/tabs/Tab.vue";
import { login, timeline } from "@/store";
import { Activite, User } from "@/models";
import { isSameDay } from "@/helpers";
import dayjs from "dayjs";

@Component({
  components: {
    Sidebar,
    Cta,
    CardActivite,
    CardActiviteOpe,
    Tag,
    Tabs,
  },
  methods: {
    isSameDay,
  },
})
export default class OpeActivites extends Vue {
  private dataReady = false;

  private tabSelected = "current";
  private tabs = [
    { key: "current", label: "En cours" },
    { key: "soon", label: "A venir" },
    { key: "past", label: "Passées" },
  ];

  async mounted(): Promise<void> {
    await timeline.activitesList();
    this.dataReady = true;

    if (this.activitesEnCours && this.activitesEnCours.length) {
      this.tabSelected = "current";
    } else {
      this.tabSelected = "soon";
    }
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private createActivite(): void {
    this.$router.push("/activites/creer");
  }

  private goToActivite(id: string) {
    this.$router.push(`/activite/${id}`);
  }

  get activitesEnCours(): Activite[] | null {
    if (this.activites) {
      return this.activites.filter(
        (a) => dayjs().isAfter(a.debut) && dayjs().isBefore(a.fin)
      );
    } else {
      return null;
    }
  }

  get activitesAVenir(): Activite[] | null {
    if (this.activites) {
      return this.activites.filter((a) => dayjs().isBefore(a.debut));
    } else {
      return null;
    }
  }

  get activitesPasse(): Activite[] | null {
    if (this.activites) {
      return this.activites.filter((a) => dayjs().isAfter(a.fin));
    } else {
      return null;
    }
  }

  get activites(): Activite[] | null {
    return timeline.getActivitesList;
  }

  get context(): User | null {
    return login.getContext;
  }
}
