var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"www www--simple"},[_c('sidebar'),_c('h1',{staticClass:"m-0 mb-12"},[_vm._v("Véhicules")]),_vm._m(0),(_vm.tabSelected === 'vehicules')?_c('div',{staticClass:"container container--gap-3"},_vm._l((_vm.vehicules),function(vehicule,index){return _c('div',{key:index,class:[
        'card card--shadow bg-white flex flex-mobile flex__align-center p-16',
        vehicule.enMission && 'border border-success',
        !vehicule.disponible && !vehicule.enMission && 'border border-danger' ]},[_c('div',{class:[
          'status-circle mr-24',
          vehicule.enMission && 'status-circle--check',
          !vehicule.disponible &&
            !vehicule.enMission &&
            'status-circle--error' ]},[(!vehicule.disponible && !vehicule.enMission)?_c('span',{staticClass:"far fa-times"}):_c('span',{class:("far fa-" + (vehicule.icon))})]),_c('div',[_c('h4',{staticClass:"m-0"},[_vm._v(_vm._s(vehicule.libelle))]),_c('p',{staticClass:"m-0 mb-8"},[_vm._v(" "+_vm._s(vehicule.disponible ? "Disponible" : vehicule.enMission ? "En mission" : "Indisponible")+" ")]),(vehicule.enMission)?_c('p',{staticClass:"m-0"},[_c('small',[_vm._v(" Mission : "+_vm._s(vehicule.mission || "inconnue")+" "),_c('span',{staticClass:"fal fa-grip-lines-vertical mx-8"}),_vm._v(" "+_vm._s(_vm.date(vehicule.debut, "DD/MM HH:mm") || "-")),_c('span',{staticClass:"fal fa-arrow-right mx-8"}),_vm._v(_vm._s(_vm.date(vehicule.fin, "DD/MM HH:mm") || "-")+" ")])]):_vm._e(),(vehicule.disponible && vehicule.mission)?_c('p',{staticClass:"m-0"},[_c('small',[_vm._v(" Prochaine mission : "+_vm._s(vehicule.mission)+" "),_c('span',{staticClass:"fal fa-grip-lines-vertical mx-8"}),_vm._v(" "+_vm._s(_vm.date(vehicule.debut, "DD/MM HH:mm") || "-")+" "),_c('span',{staticClass:"fal fa-arrow-right mx-8"}),_vm._v(" "+_vm._s(_vm.date(vehicule.fin, "DD/MM HH:mm") || "-")+" ")])]):_vm._e(),(vehicule.disponible && !vehicule.mission)?_c('p',{staticClass:"m-0"},[_c('small',[_vm._v(" Aucune mission à venir ")])]):_vm._e(),(!vehicule.disponible && !vehicule.enMission)?_c('p',{staticClass:"m-0"},[_vm._m(1,true)]):_vm._e()])])}),0):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-48"},[_c('div',{staticClass:"flex flex-mobile flex__justify-center"},[_c('span',{staticClass:"far fa-ambulance font-80 text-neutral--200"})]),_c('p',{staticClass:"font-18 bold m-0 text-center text-neutral--400 mt-48 mb-12 ml-48 mr-48"},[_vm._v(" Prêt à voir vos véhicules d'une autre façon ? ")]),_c('p',{staticClass:"text-neutral--300 m-0 mb-32 text-center ml-48 mr-48"},[_vm._v(" Nous allons retrailler l'interface de vos véhicules dans V3 pour vous proposer une expérience plus complète. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',[_vm._v(" Ce véhicule n'est pas disponible. "),_c('span',{staticClass:"fal fa-grip-lines-vertical mx-8"}),_vm._v(" Il se peut que celui-ci soit en maintenance ou indisponible pour une autre raison. ")])}]

export { render, staticRenderFns }