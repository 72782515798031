



































































































































































































import { getLetterUser } from "@/helpers";
import { User } from "@/models";
import { login } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import Tag from "@/components/tags/Tag.vue";
import ClickOutside from "vue-click-outside";

@Component({
  components: { Tag },
  directives: {
    ClickOutside,
  },
})
export default class Sidebar extends Vue {
  private state = false;

  private switchState(): void {
    this.state = !this.state;
  }

  private close(): void {
    this.state = false;
  }

  get context(): User | null {
    return login.getContext;
  }

  private letterUser(nom_usage: string): string {
    return getLetterUser(nom_usage);
  }
}
