






































import { Component, Prop, Vue } from "vue-property-decorator";
import Tag from "@/components/tags/Tag.vue";
import Cta from "@/components/buttons/Button.vue";
import { ProchaineActivite } from "@/models";
import { isSameDay } from "@/helpers";
import dayjs from "dayjs";

@Component({
  components: { Tag, Cta },
  methods: {
    isSameDay,
  },
})
export default class CardProchaineActivite extends Vue {
  @Prop({ required: true }) activite!: ProchaineActivite | null;

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private openSidebar(): void {
    this.$emit("sidebarActivite", this.activite?.id);
  }
}
