

















































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import Tabs from "@/components/tabs/Tab.vue";
import { vehicules } from "@/store";
import { Vehicule } from "@/models";
import dayjs from "dayjs";

@Component({
  components: {
    Sidebar,
    Cta,
    Tag,
    Tabs,
  },
})
export default class OpeVehicules extends Vue {
  private tabSelected = "vehicules";
  private tabs = [
    { key: "vehicules", label: "Véhicules" },
    { key: "stationservices", label: "Carburants" },
  ];

  private rechercheStation = "";

  mounted(): void {
    vehicules.vehicules();
    // vehicules.stationsServices();
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  // private stationsList(recherche: string): any {
  //   if (recherche) {
  //     return this.stations.filter((s: { nom: string }) =>
  //       s.nom.toLowerCase().includes(recherche.toLowerCase())
  //     );
  //   } else {
  //     return this.stations;
  //   }
  // }

  get vehicules(): Vehicule[] | null {
    return vehicules.getVehicules;
  }

  // get stations(): any | null {
  //   return vehicules.getStationsServices;
  // }
}
