











































































































import { Component, Vue } from "vue-property-decorator";
import ButtonAction from "@/components/buttons/Button.vue";
import { login, toast } from "@/store";
import { TYPE } from "@/store/modules/toast.module";
import LoginService from "@/services/login.service";
import { LocalStorageApp } from "@/utils/localstorage";
import { JwtManage } from "@/utils/jwt";
import { User } from "@/models";
import { Http } from "@/utils/http";

@Component({
  components: { ButtonAction },
})
export default class Login extends Vue {
  private ready = false;
  private context: User | null = null;
  private username = "";
  private userpasswd = "";
  private useOTP = false;

  private backgroundImage = {
    backgroundImage: `url(${require("@/assets/images/photos/jop-lco.jpg")})`,
  };

  async mounted(): Promise<void> {
    const token: string = LocalStorageApp.getItem("pcp.token") ?? "";
    const tokenValue = JwtManage.decode(token);
    this.username = tokenValue?.user_username ?? "";
    if (tokenValue) {
      this.context =
        (await LoginService.contextUnlock(tokenValue.user_id)) ?? null;
      this.ready = true;
    } else {
      this.goToLogin();
    }
  }

  private goToLogin() {
    this.$router.push("/connexion?change_compte=true");
  }

  private async receiveCodeSlack() {
    if (this.username) {
      if (await LoginService.codeSlack(this.username)) {
        this.useOTP = true;
      }
    }
  }

  private updatePasswd(input: number) {
    this.userpasswd = input as unknown as string;
  }

  private async login() {
    if (this.username && this.userpasswd) {
      if (await LoginService.login(this.username, this.userpasswd)) {
        this.$router.replace("/");
      } else {
        this.userpasswd = "";
      }
    } else {
      toast.createAndPushToast(
        "Veuillez saisir un nom d'utilisateur et un mot de passe",
        "",
        true,
        TYPE.INFO
      );
    }
  }
}
