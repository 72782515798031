










































































































































































































































































import { Activite, User } from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";

import tag from "@/components/tags/Tag.vue";
import cta from "@/components/buttons/Button.vue";
import dayjs from "dayjs";
import { login } from "@/store";

@Component({
  components: { tag, cta },
})
export default class PanelActivite extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) data!: Activite | null;

  private closePanel(): void {
    this.$emit("close");
  }

  private openModification(): void {
    this.$router.push(`/activite/${this.data?.id}`);
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  get context(): User | null {
    return login.getContext;
  }

  get personnesEngages(): string {
    if (this.data && this.data.recensement) {
      return this.data.recensement
        ?.filter((r) => r.etat.id === "ENGAGE")
        .map((r) => {
          return r.nom_usage;
        })
        .join(", ");
    } else {
      return "Indisponible";
    }
  }

  get personnesNonDisponible(): string {
    if (this.data && this.data.recensement) {
      return this.data.recensement
        ?.filter((r) => r.etat.id === "NDISP")
        .map((r) => {
          return r.nom_usage;
        })
        .join(", ");
    } else {
      return "Indisponible";
    }
  }

  get etatSecouriste(): string {
    if (this.data && this.data.info_secouriste) {
      if (this.data.info_secouriste.statut === "NON_REPONDU") {
        return "Non répondu";
      } else if (this.data.info_secouriste.statut === "ENGAGE") {
        return "Engagé";
      } else if (this.data.info_secouriste.statut === "NDISP") {
        return "Non disponible";
      } else if (this.data.info_secouriste.statut === "NON_RETENU") {
        return "Non retenu";
      } else if (this.data.info_secouriste.statut === "RETENU") {
        return "Retenu";
      }
    }
    return "Information indisponible";
  }
}
