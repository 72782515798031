



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Radio extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop() label?: string;
  @Prop() border?: string;
  @Prop() option!: string;

  private switchValue(): void {
    this.$emit("input", this.option);
  }
}
