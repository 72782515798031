import { Activite, ActiviteDTO } from "@/models";

export const mapOutActivite = (activite: Activite): ActiviteDTO => {
  return {
    libelle: activite.libelle ?? "",
    debut: activite.debut ?? "",
    fin: activite.fin ?? "",
    do_etat: activite.do?.id ?? "",
    etat_id: "OPEN",
    type_id: activite.type?.id ?? "",
    lieu: activite.lieu ?? "",
    reversion: activite.reversion ?? "",
  };
};
